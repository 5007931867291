import './styles.scss';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';

const MobileSocialBlock = () => {
    const { footer_instagram_link, footer_facebook_link, footer_youtube_link } = useSelector(selectTranslations);
    return !footer_instagram_link && !footer_facebook_link && !footer_youtube_link ? (
        false
    ) : (
        <div className="social-media-section">
            <p>We're on social media</p>
            <div className="social-media-wrapper">
                {footer_instagram_link && (
                    <Link target="_blank" to={footer_instagram_link} className="button instagram" />
                )}
                {footer_facebook_link && <Link target="_blank" to={footer_facebook_link} className="button facebook" />}
                {footer_youtube_link && <Link target="_blank" to={footer_youtube_link} className="button youtube" />}
            </div>
        </div>
    );
};
export default MobileSocialBlock;
