import { CareerForm } from 'components/Form/CareerForm';
import FormSection from '..';
import CareerFormSidebar from '../Sidebars/Career';

const CareerFormSection = () => (
    <FormSection sidebar={<CareerFormSidebar />} className="career-form-section">
        <CareerForm />
    </FormSection>
);

export default CareerFormSection;
