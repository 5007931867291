import { createSelector, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { determinate_popup_is_showed } from 'store/helpers';

const uislice = createSlice({
    name: 'uislice',
    initialState: {
        isNavigationItemHover: false,
        isCookieAgree: Cookies.get('cookieIsAgree') || false,
        lastLocation: {
            pathname: null,
            state: {},
        },
        isDeterminateOpen: !Boolean(Cookies.get(determinate_popup_is_showed)),
        isCountryOpen: false,
    },
    reducers: {
        setLastLocation: (state, action) => {
            state.lastLocation = action.payload;
        },
        setNavigationItemHover: (state, action) => {
            state.isNavigationItemHover = action.payload;
        },
        setAgreeCookie: state => {
            Cookies.set('cookieIsAgree', 'true');
            state.isCookieAgree = true;
        },
        setDeterminateClose: state => {
            state.isDeterminateOpen = false;
        },
        setCountryOpen: (state, action) => {
            state.isCountryOpen = action.payload;
        },
    },
});

export const selectIsNavigationItemHover = createSelector([state => state.ui], data => data.isNavigationItemHover);

export const selectIsCookieAgree = createSelector([state => state.ui], data => data.isCookieAgree);

export const selectLastLocation = createSelector([state => state.ui], data => data.lastLocation);

export const selectIsDeterminateOpen = createSelector([state => state.ui], data => data.isDeterminateOpen);

export const selectIsCountryOpen = createSelector([state => state.ui], data => data.isCountryOpen);

export const { setNavigationItemHover, setAgreeCookie, setLastLocation, setDeterminateClose, setCountryOpen } =
    uislice.actions;

export default uislice.reducer;
