import { Button } from 'components/buttons/Button/Button';

const ResultBlock = ({ data, title, children, link, linkText }) =>
    !!data.length && (
        <div className="result-category-block">
            <div className="result-category-block__title">{title}</div>
            <div className="result-category-block__content">{children}</div>
            {link && (
                <Button href={link} type="learn-more-arrow" className="result-category-block__button">
                    {linkText}
                </Button>
            )}
        </div>
    );

export default ResultBlock;
