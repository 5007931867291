import './styles.scss';
import MarqueeRow from './MarqueeRow';

const MarqueeGallery = ({ images = [], title }) => {
    const firstRow = images.slice(0, images.length / 2);
    const secondRow = images.slice(images.length / 2);
    return (
        images.length && (
            <section className="container">
                <div className="marquee-gallery-section">
                    <h2 className="has-animation">{title}</h2>
                    <div className="marquee-gallery">
                        <MarqueeRow list={firstRow} time={35} />
                        <MarqueeRow list={secondRow} toRight time={50} />
                    </div>
                </div>
            </section>
        )
    );
};

export default MarqueeGallery;
