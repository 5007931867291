import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const ThankYouPage = () => {
    const {
        cta_thanks_title,
        cta_thanks_description,
        cta_thanks_image,
        cta_thanks_social_text,
        footer_instagram_link,
        footer_facebook_link,
        footer_youtube_link,
    } = useSelector(selectTranslations);

    return (
        <div className="thankyou-container">
            <div className="thankyou-wrapper" style={{ backgroundImage: `url("${cta_thanks_image}")` }}>
                <div className="thankyou-background">
                    <h3>{cta_thanks_title}</h3>
                    <p className="body1">{cta_thanks_description}</p>
                    <div className="thankyou-bottom-wrapper">
                        <div className="thankyou-background-bottom">
                            <p className="body1">{cta_thanks_social_text}</p>
                            <div className="social-buttons">
                                <Link target="_blank" to={footer_instagram_link} className="button instagram" />
                                <Link target="_blank" to={footer_facebook_link} className="button facebook" />
                                <Link target="_blank" to={footer_youtube_link} className="button youtube" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
