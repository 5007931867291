import React, { useEffect } from 'react';
import HeaderButton from '../HeaderButton';
import SearchComponent from './SearchComponent';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { resetSearch } from 'store/slices/SearchSlice';
import { useLocation } from 'react-router-dom';

const Search = ({ isOpen, setIsOpen }) => {
    const dispatch = useDispatch();

    const onToggleSearch = () => {
        setIsOpen(!isOpen);
        if (isOpen === false) {
            dispatch(resetSearch());
        }
    };
    const location = useLocation();

    useEffect(() => {
        setIsOpen(false);
        dispatch(resetSearch());
    }, [dispatch, location, setIsOpen]);

    return (
        <div className="search-container">
            <HeaderButton iconName={isOpen ? 'close' : 'search'} onClick={onToggleSearch} />
            {isOpen && (
                <div className={`search-dropdown ${isOpen ? 'opened' : ''}`}>
                    <SearchComponent />
                </div>
            )}
        </div>
    );
};

export default Search;
