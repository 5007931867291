import { useSelector } from 'react-redux';
import './styles.scss';
import { ReactComponent as Icon } from 'assets/icons/filter.svg';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';

const Filter = ({ children, onReset, hasSelected, title = 'Filter', withoutSticky = false }) => {
    const { reset_filter } = useSelector(selectTranslations);

    return (
        <div className={`filter-sidebar ${withoutSticky ? 'without-sticky' : ''}`}>
            <div className="filter-sidebar__header">
                <Icon />
                {title}
            </div>
            <div className="filter-sidebar__body">{children}</div>
            {hasSelected && (
                <button className="filter-sidebar-reset" onClick={onReset}>
                    {reset_filter}
                </button>
            )}
        </div>
    );
};

export default Filter;
