const TeamMemberCard = ({ data }) => {
    const { image, title, description } = data;

    return (
        <div className="team-member-card">
            <div className="team-member-card__img">
                <img src={image} alt={title} />
            </div>
            <div className="description-demibold">{title}</div>
            <div className="body1 opacity-80">{description}</div>
        </div>
    );
};

export default TeamMemberCard;
