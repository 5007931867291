import { createSlice, createSelector } from '@reduxjs/toolkit';
import { getLegalInfoPage, getTermsConditions } from 'api/AdditionalPages';
import { fetchWithLanguage } from 'store/helpers';

export const fetchLegalInfo = fetchWithLanguage('fetchLegalInfo', getLegalInfoPage, (_, { getState }) => {
    const { data } = getState().additionalPagesState.lagalInfo;
    return !data;
});
export const fetchTermsConditions = fetchWithLanguage('fetchTermsConditions', getTermsConditions, (_, { getState }) => {
    const { data } = getState().additionalPagesState.termsConditions;
    return !data;
});

const additionalPagesSlice = createSlice({
    name: 'additionalPagesSlice',
    initialState: {
        lagalInfo: {},
        termsConditions: {},
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchLegalInfo.fulfilled, (state, action) => {
            state.lagalInfo = action.payload;
        });
        builder.addCase(fetchTermsConditions.fulfilled, (state, action) => {
            state.termsConditions = action.payload;
        });
    },
});

export const selectLegalInfoPage = createSelector(
    [state => state.additionalPagesState.lagalInfo],
    lagalInfo => lagalInfo.data || {},
);
export const selectTermsConditionsPage = createSelector(
    [state => state.additionalPagesState.termsConditions],
    termsConditions => termsConditions.data || {},
);
export const selectLegalInfoPageIsLoading = createSelector(
    [state => state.additionalPagesState.lagalInfo],
    lagalInfo => lagalInfo.status !== 'success',
);
export const selectTermsConditionsPageIsLoading = createSelector(
    [state => state.additionalPagesState.termsConditions],
    termsConditions => termsConditions.status !== 'success',
);

export default additionalPagesSlice.reducer;
