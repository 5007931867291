import { createSlice, createSelector } from '@reduxjs/toolkit';
import { getAboutUsPage, getEmployees } from 'api/AboutUs';
import { fetchWithLanguage } from 'store/helpers';

export const fetchAboutUs = fetchWithLanguage('fetchAboutUs', getAboutUsPage, (_, { getState }) => {
    const { data } = getState().aboutUsState.about;
    return !data;
});
export const fetchEmployees = fetchWithLanguage('fetchEmployees', getEmployees);

const aboutUsSlice = createSlice({
    name: 'aboutUsSlice',
    initialState: {
        about: {},
        employees: {
            data: [],
            next: false,
        },
        employeesIsLoading: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchAboutUs.fulfilled, (state, action) => {
            state.about = action.payload;
        });
        builder.addCase(fetchEmployees.pending, (state, action) => {
            const { meta } = action;
            if (meta.arg.page === 1) {
                state.employeesIsLoading = true;
            }
        });
        builder.addCase(fetchEmployees.fulfilled, (state, action) => {
            const { payload, meta } = action;
            state.employees.next = payload.next;

            if (meta.arg.page === 1) {
                state.employees.data = payload.data;
            } else {
                state.employees.data.push(...payload.data);
            }
            state.employeesIsLoading = false;
        });
    },
});

export const selectAboutUsPage = createSelector([state => state.aboutUsState.about], about => about.data || {});

export const selectEmployees = createSelector([state => state.aboutUsState.employees], employees => employees);

export const selectEmployeesIsLoading = createSelector(
    [state => state.aboutUsState.employeesIsLoading],
    employeesIsLoading => employeesIsLoading,
);

export const selectAboutUsPageIsLoading = createSelector(
    [state => state.aboutUsState.about],
    about => about?.status !== 'success',
);

export default aboutUsSlice.reducer;
