import { LabelButton } from 'components/buttons/LabelButton/LabelButton';
import GradientText from 'components/GradientText';
import React from 'react';
import TeamsCards from '../TeamsCards';
import { selectAboutUsPage } from 'store/slices/AboutUsSlice';
import { useSelector } from 'react-redux';

const TeamSection = ({ showMore }) => {
    const { team_title, team_tag } = useSelector(selectAboutUsPage);

    return (
        <section className="container">
            <div className="inner-container type-2">
                <div className="main-text-container with-absolute-label">
                    <LabelButton>{team_tag}</LabelButton>
                    <div className="offset-left-4">
                        <GradientText size="middle" className="has-animation">
                            <span dangerouslySetInnerHTML={{ __html: team_title }} />
                        </GradientText>
                    </div>
                </div>
                <TeamsCards showMore={showMore} />
            </div>
        </section>
    );
};

export default TeamSection;
