import BenefitsCards from 'components/BenefitsCards';

const BenefitsBox = ({ title, data, children = undefined }) => (
    <div className="box">
        {title && <h3>{title}</h3>}
        <BenefitsCards data={data} />
        {children}
    </div>
);

export default BenefitsBox;
