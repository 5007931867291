import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLegalInfo, selectLegalInfoPage, selectLegalInfoPageIsLoading } from 'store/slices/AdditionalPagesSlice';
import AdditionalPage from 'pages/AdditionalPage';
import { useAnimation } from 'hooks/useAnimation';
import Preloader from 'Layout/Preloader';

const LegalInfo = () => {
    const dispatch = useDispatch();
    const loading = useSelector(selectLegalInfoPageIsLoading);

    const { fs_title, fs_background, fs_button, blocks = [] } = useSelector(selectLegalInfoPage);

    const contentData = blocks.map(item => ({ block: item }));

    React.useEffect(() => {
        // @ts-ignore
        dispatch(fetchLegalInfo());
    }, [dispatch]);

    const container = useRef();

    useAnimation({
        items: ['.with-sidebar-layout__left', '.box'],
        container,
        dependencies: [loading],
    });

    if (loading) return <Preloader />;

    return (
        <AdditionalPage
            pageRef={container}
            title={fs_title}
            background={fs_background}
            buttonText={fs_button}
            pageContentData={contentData}
        />
    );
};

export default LegalInfo;
