import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LabelButton } from 'components/buttons/LabelButton/LabelButton';
import GradientText from 'components/GradientText';
import ProductItems from 'components/ProductItems';
import Banner from 'components/Banner';
import DefaultForm from 'components/FormSection/Forms/DefaultForm';
import {
    selectCropNutritionPage,
    fetchCropNutritionPage,
    selectCropNutritionPageCrops,
    selectCropNutritionPageIsLoading,
} from 'store/slices/CropNutritionSlice';
import { ROUTES } from 'constants/routes';
import { fetchCropNutritionPageCrops } from 'store/slices/CropNutritionSlice';
import { type_nutrition } from 'api/constants';
import { scrollToSection } from 'helpers';
import Preloader from 'Layout/Preloader';
import { useAnimation } from 'hooks/useAnimation';

const CropNutritionPrograms = () => {
    const dispatch = useDispatch();
    const loading = useSelector(selectCropNutritionPageIsLoading);

    const { fs_title, fs_title_background, fs_view_button, agricultural_tag, agricultural_title } =
        useSelector(selectCropNutritionPage);

    const crops = useSelector(selectCropNutritionPageCrops);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchCropNutritionPage());
        // @ts-ignore
        dispatch(fetchCropNutritionPageCrops({ type: type_nutrition, all: true }));
    }, [dispatch]);

    const handleClick = () => scrollToSection('box', 100);

    const container = useRef();

    useAnimation({
        items: ['.with-sidebar-layout'],
        container,
        dependencies: [loading],
    });

    if (loading) return <Preloader />;

    return (
        <div ref={container}>
            <Banner
                title={fs_title}
                image={fs_title_background}
                buttonText={fs_view_button}
                onClickButton={handleClick}
            />

            <section className="container">
                <div className="inner-container type-3">
                    <div className="main-text-container">
                        <LabelButton>{agricultural_tag}</LabelButton>
                        <GradientText className="has-animation">
                            <span dangerouslySetInnerHTML={{ __html: agricultural_title }} />
                        </GradientText>
                    </div>
                </div>

                <div className="box">
                    <ProductItems data={crops} baseRoute={ROUTES.cropNutritionPrograms} withSixItems />
                </div>
            </section>

            <DefaultForm />
        </div>
    );
};

export default CropNutritionPrograms;
