import './styles.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import NavigationButton from './NavigationButton';
import React from 'react';
import NewsCard from 'components/NewsCards/NewsCard';
import { fetchPostsSlider, selectArticleSliderPosts } from 'store/slices/BlogSlice';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';

const NewsSlider = () => {
    const { slug } = useParams();
    const dispatch = useDispatch();
    const posts = useSelector(selectArticleSliderPosts);
    const { post_more_posts } = useSelector(selectTranslations);

    React.useEffect(() => {
        dispatch(
            // @ts-ignore
            fetchPostsSlider(),
        );
    }, [dispatch, slug]);

    const filteredPosts = posts.filter(item => item.slug !== slug);

    const modules = [Navigation, Pagination];
    const navigation = {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    };
    const breakpoints = {
        320: { slidesPerView: 1 },
        768: { slidesPerView: 2 },
        1024: { slidesPerView: 3 },
    };

    return (
        !!filteredPosts.length && (
            <section className="container">
                <div className="news-slider-section">
                    <h2>{post_more_posts}</h2>
                    <div className="news-slider">
                        <div className="news-slider__navigation">
                            <NavigationButton prev />
                            <NavigationButton />
                        </div>
                        <Swiper
                            pagination
                            modules={modules}
                            navigation={navigation}
                            breakpoints={breakpoints}
                            spaceBetween={8}
                        >
                            {filteredPosts.map(item => (
                                <SwiperSlide key={item.title}>
                                    <NewsCard data={item} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </section>
        )
    );
};

export default NewsSlider;
