import { LabelButton } from 'components/buttons/LabelButton/LabelButton';
import './styles.scss';
import { useSelector } from 'react-redux';
import ProductItem from './ProductItem';
import GradientText from 'components/GradientText';
import WithSidebarLayout from 'Layout/WithSidebar';
import { selectHomepage } from 'store/slices/HomepageSlice';
import { selectMainProducts } from 'store/slices/ProductsSlice';

const Products = () => {
    const { products_tag, products_title } = useSelector(selectHomepage);
    const products = useSelector(selectMainProducts);

    return (
        <section className="container our-products">
            <div className="inner-container type-3">
                <div className="main-text-container with-absolute-label">
                    <LabelButton>{products_tag}</LabelButton>
                    <div className="offset-left-4">
                        <GradientText size="middle has-animation">
                            <span dangerouslySetInnerHTML={{ __html: products_title }} />
                        </GradientText>
                    </div>
                </div>
            </div>
            <WithSidebarLayout containerType="type-2" Sidebar={<></>} breakpoint="lg">
                <div className="homepage-product-items">
                    {products.map((product, index) => (
                        <ProductItem index={index} key={product.slug} data={product} />
                    ))}
                </div>
            </WithSidebarLayout>
        </section>
    );
};

export default Products;
