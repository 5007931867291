import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as X } from 'assets/icons/X.svg';
import { useSelector } from 'react-redux';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';

const SearchInput = ({ value, onClear, onChange, inputRef }) => {
    const { menu_search_placeholder, menu_clear_button_text } = useSelector(selectTranslations);

    return (
        <label className="search-control">
            <SearchIcon />
            <input
                ref={inputRef}
                value={value}
                onChange={onChange}
                type="text"
                placeholder={menu_search_placeholder}
                className="body1 search-control__input"
            />
            {!!value && (
                <button className="search-control__clear" onClick={onClear}>
                    <span>{menu_clear_button_text}</span>
                    <X />
                </button>
            )}
        </label>
    );
};

export default SearchInput;
