import { Link } from 'react-router-dom';
import './styles.scss';

export const Button = ({ type = '', href = '', children = '', className = '', onClick = undefined }) => {
    return (
        <Link to={href} className={`button ${type} ${className} ${children && 'has-children'}`} onClick={onClick}>
            <div></div>
            <span>{children}</span>
        </Link>
    );
};
