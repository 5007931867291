import './styles.scss';
// import { ReactComponent as Icon1 } from 'assets/icons/circle-gradient-phone.svg';
// import { ReactComponent as Icon2 } from 'assets/icons/circle-gradient-email.svg';
// import { ReactComponent as Icon3 } from 'assets/icons/circle-gradient-pin.svg';

// const data = [
//     { icon: <Icon1 />, title: 'Phone', text: '+49 173 762 7701' },
//     { icon: <Icon2 />, title: 'E-mail', text: 'info@uniferx.com' },
//     { icon: <Icon3 />, title: 'Office address', text: 'Strandstraße 6 18211 Ostseebad Nienhagen, Deutschland' },
// ];

const ContactsCards = ({ data = [] }) => (
    <div className="contacts-cards">
        {data.map(({ icon, title, text }) => (
            <div className="contact-card" key={title}>
                <div className="body3 contact-card__title">
                    <img src={icon} alt="icon" />
                    {title}
                </div>
                <div className="description-demibold">{text}</div>
            </div>
        ))}
    </div>
);

export default ContactsCards;
