import { Button } from 'components/buttons/Button/Button';
import './styles.scss';
import GradientText from 'components/GradientText';
import { useSelector } from 'react-redux';
import { ROUTES } from 'constants/routes';
import { selectHomepage } from 'store/slices/HomepageSlice';

const TrialResults = () => {
    const {
        discover_information_description,
        discover_information_title,
        discover_nutrition_background,
        discover_nutrition_description,
        discover_nutrition_title,
        discover_trial_description,
        discover_trial_image,
        discover_trial_title,
        discover_title,
    } = useSelector(selectHomepage);

    return (
        <>
            <section className="container">
                <div className="trial-results">
                    <GradientText size="middle" textColor="white" className="has-animation">
                        <span dangerouslySetInnerHTML={{ __html: discover_title }} />
                    </GradientText>
                    <div className="discover">
                        <div className="discover-1 discover-item">
                            <img src={discover_trial_image} alt="charts" />
                            <div className="discover-1-content">
                                <div className="discover-title">{discover_trial_title}</div>
                                <p className="discover-text">{discover_trial_description}</p>
                                <Button href={ROUTES.trialResults} type="learn-more-arrow" />
                            </div>
                        </div>
                        <div
                            className="discover-2 discover-item"
                            style={{
                                backgroundImage: `url("${discover_nutrition_background}")`,
                            }}
                        >
                            <div className="discover-title">{discover_nutrition_title}</div>
                            <p className="discover-text">{discover_nutrition_description}</p>
                            <Button href={ROUTES.cropNutritionPrograms} type="learn-more-arrow" />
                        </div>
                        <div className="discover-3 discover-item">
                            <div className="discover-title">{discover_information_title}</div>
                            <p className="discover-text">{discover_information_description}</p>
                            <Button href={ROUTES.informationMaterials} type="learn-more-arrow" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TrialResults;
