import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const markers = false;

const duration = 0.4;
const ease = 'power1.in';
const y = 10;
const start = 'top 75%';

const debug = item => {
    if (!markers) {
        return {};
    } else {
        return {
            onStart: () => {
                console.log('onStart', { item });
            },
            onComplete: () => {
                console.log('onComplete', { item });
            },
        };
    }
};

const animationDefault = item =>
    gsap.from(item, {
        y: item.classList.contains('label-button') ? 0 : y,
        opacity: 0,
        ease: ease,
        duration: duration,
        scrollTrigger: {
            trigger: item,
            start: start,
            end: 'top',
            markers: markers,
        },
        ...debug(item),
    });

const animationWithStagger = ({ item, trigger }) =>
    gsap.from(item, {
        opacity: 0,
        ease: ease,
        duration: duration,
        stagger: 0.15,
        scrollTrigger: {
            trigger: trigger,
            start: start,
            markers: markers,
        },
        ...debug(item),
    });

const animationWithoutScroll = ({ item }) =>
    gsap.from(item, {
        y: y,
        opacity: 0,
        ease: ease,
        duration: duration,
        ...debug(item),
    });

const defaultItems = ['.has-animation', '.label-button', '.form-section'];

export const useAnimationWithoutDefaltsItems = ({ items, container, dependencies = [] }) => {
    if (markers) {
        console.log('useAnimation', items, container);
    }
    useGSAP(
        () => {
            let targets = gsap.utils.toArray(items);
            targets.forEach(item => animationDefault(item));
        },
        { dependencies, scope: container, revertOnUpdate: true },
    );
};
export const useAnimation = ({ items, container, dependencies = [] }) => {
    if (markers) {
        console.log('useAnimation', items, container);
    }
    useGSAP(
        () => {
            let targets = gsap.utils.toArray([...defaultItems, ...items]);
            targets.forEach(item => animationDefault(item));
        },
        { dependencies, scope: container, revertOnUpdate: true },
    );
};

export const useAnimationWithStagger = ({ item, container, trigger, dependencies = [] }) => {
    if (markers) {
        console.log('useAnimationWithStagger', item, container);
    }

    useGSAP(
        () => {
            animationWithStagger({ item, trigger });
        },
        { dependencies, scope: container, revertOnUpdate: true },
    );
};

export const useAnimationWithWithoutScroll = ({ item, container, dependencies = [] }) => {
    if (markers) {
        console.log('useAnimationWithWithoutScroll', item, container);
    }
    useGSAP(
        () => {
            animationWithoutScroll({ item });
        },
        { dependencies, scope: container, revertOnUpdate: true },
    );
};
