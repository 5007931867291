import { useState } from 'react';

const InputFile = ({ label, children, setFile }) => {
    const [fileName, setFileName] = useState('');

    const handleFileUpload = event => {
        const fileInput = event.target;
        setFileName(fileInput?.files[0]?.name || children);
        setFile(fileInput?.files[0]);
    };

    const attached = !!fileName ? 'file-attached' : '';

    return (
        <div className="form-control-container">
            <span className="form-control__label">{label}</span>
            <div className="attach-file-wrapper">
                <label className={`button attach-file ${attached}`}>
                    {fileName || children}
                    <input type="file" onChange={handleFileUpload} />
                </label>
            </div>
        </div>
    );
};

export default InputFile;
