import GradientText from 'components/GradientText';
import { Button } from 'components/buttons/Button/Button';
import { LabelButton } from 'components/buttons/LabelButton/LabelButton';
import './styles.scss';
import { useSelector } from 'react-redux';
import { ROUTES } from 'constants/routes';
import { selectHomepage } from 'store/slices/HomepageSlice';

const AboutUs = () => {
    const { about_button, about_tag, about_text, about_title } = useSelector(selectHomepage);

    return (
        <section className="container">
            <div className="inner-container type-2">
                <div className="main-text-container">
                    <LabelButton>{about_tag}</LabelButton>
                    <GradientText className="has-animation">
                        <p dangerouslySetInnerHTML={{ __html: about_title }} />
                    </GradientText>
                    <div className="offset-left-4 has-animation">
                        <GradientText size="small">
                            <p dangerouslySetInnerHTML={{ __html: about_text }} />
                        </GradientText>
                        <Button href={ROUTES.aboutUs} type="learn-more-arrow" className="business-philosophy-button">
                            {about_button}
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
