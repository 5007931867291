import React, { useCallback } from 'react';

const TabButton = ({ title, setSelectedTab, index, isActive }) => {
    const handleOnClick = useCallback(() => {
        setSelectedTab(index);
    }, [setSelectedTab, index]);

    return (
        <button className={`tab-button ${isActive ? 'active' : ''}`} onClick={handleOnClick}>
            {title}
        </button>
    );
};

export default TabButton;
