import './styles.scss';

const TableBox = ({ title, table, description }) => (
    <div className="box table-box">
        {title && <h3>{title}</h3>}
        <div className="table-box__table" dangerouslySetInnerHTML={{ __html: table }} />
        {!!description && (
            <div className="table-box__description" dangerouslySetInnerHTML={{ __html: description }}></div>
        )}
        <div className="table-box__piece" />
    </div>
);

export default TableBox;
