import { getTextFromJSX } from "./getTextFromJSX";

export const getFormatedFilterOptions = (options) => {
  if (!Array.isArray(options)) return [];

  const formatedOptions = options.map(item => ({
    ...item,
    name: typeof item.name === 'object' ? getTextFromJSX(item.name) : item.name
  }));

  return formatedOptions;
}