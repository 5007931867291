import React, { useState, useRef, useEffect } from 'react';
import './styles.scss';
import { useSelector } from 'react-redux';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import { getFormatedFilterOptions } from 'utils/getFormatedFilterOptions';

const Dropdown = ({ label = '', options = [], onChange = undefined, selectedValue }) => {
    const { dropdown_not_selected_text } = useSelector(selectTranslations);

    const [isActive, setIsActive] = useState(false);
    const dropdownRef = useRef(null);
    const formatedOptions = getFormatedFilterOptions(options);

    useEffect(() => {
        const handleClickOutside = e => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setIsActive(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleDropdownToggle = () => {
        setIsActive(!isActive);
    };

    const handleItemClick = item => {
        setIsActive(false);
        onChange(item);
    };

    return (
        <label className="new-dropdown form-control-container">
            {label && <div className="form-control__label">{label}</div>}
            <div
                className={`select-input ${isActive ? '-active' : ''} ${!!selectedValue ? '-selected' : ''}`}
                ref={dropdownRef}
            >
                <div className="--dropdown__value" onClick={handleDropdownToggle}>
                    <span className="--dropdown__value-text body1">
                        {!!selectedValue?.name ? (
                            <div className="selected-item">
                                {selectedValue?.icon && <img src={selectedValue?.icon} alt={selectedValue?.name} />}
                                {selectedValue?.name}
                            </div>
                        ) : (
                            <div>{dropdown_not_selected_text}</div>
                        )}
                    </span>
                </div>
                <ul>
                    {formatedOptions
                        .filter(el => el?.name !== selectedValue?.name && !!el?.name)
                        .map((el, index) => (
                            <li className="body1" onClick={() => handleItemClick(el)} key={el.value}>
                                {el?.icon && <img src={el?.icon} alt={el.name} />}
                                {el.name}
                            </li>
                        ))}
                </ul>
            </div>
        </label>
    );
};

export default Dropdown;
