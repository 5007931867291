import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';

const Logo = () => {
    const { menu_logo } = useSelector(selectTranslations);
    return (
        <Link to="/">
            <img src={menu_logo} alt="" className="main-logo-icon" />
        </Link>
    );
};

export default Logo;
