import React, { useEffect, useState } from 'react';
import { ROUTES } from 'constants/routes';
import { Routes, Route, useLocation } from 'react-router-dom';
import Products from 'pages/Products';
import Resources from 'pages/Resources';
import MainLayout from 'Layout/main';
import AboutProducts from 'pages/AboutProduct';
import CropNutritionPrograms from 'pages/CropNutritionPrograms';
import TrialResults from 'pages/TrialResults';
import DetailedOfCropNutrition from 'pages/DetailedOfCropNutrition';
import DetailedTrialResults from 'pages/DetailedTrialResults';
import InformationMaterials from 'pages/InformationMaterials';
import AboutUs from 'pages/AboutUs';
import News from 'pages/News';
import Article from 'pages/Article';
import DetailedJob from 'pages/DetailedJob';
import Career from 'pages/Career';
import TermsAndConditions from 'pages/TermsAndConditions';
import LegalInfo from 'pages/LegalInfo';
import Contacts from 'pages/Contacts';
import Page404 from 'pages/Page404';
import { Homepage } from 'pages/Homepage/Homepage';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHomepage, selectHomepageIsLoading } from 'store/slices/HomepageSlice';
import { fetchMainProducts } from 'store/slices/ProductsSlice';
import { fetchTranslations, selectTranslationsIslLoading } from 'store/slices/GeneralTranslationsSlice';
import {
    fetchLanguages,
    fetchCountries,
    selectLanguagesAndCountriesIsLoading,
    selectCurrentCountryCode,
    languageKey,
    countryKey,
} from 'store/slices/languageSlice';
import Preloader from 'Layout/Preloader';
import { fetchLatestPostsForHomepage, selectLatestPostHomepageIsLoading } from 'store/slices/BlogSlice';
import { homepage_posts_limit } from 'api/constants';
import useTimeout from 'hooks/useTimeout';
import { setLastLocation } from 'store/slices/UI-Slice';

export const delay = 2000;

const App = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const homepageIsLoading = useSelector(selectHomepageIsLoading);
    const translationsIslLoading = useSelector(selectTranslationsIslLoading);
    const latestPostHomepageIsLoading = useSelector(selectLatestPostHomepageIsLoading);
    const languagesAndCountriesIsLoading = useSelector(selectLanguagesAndCountriesIsLoading);
    const country_code = useSelector(selectCurrentCountryCode);

    const [loading, setLoading] = useState(true);

    const handleKeyDown = e => {
        if (
            (e.metaKey && e.key === 'r') ||
            (e.ctrlKey && e.key === 'r') ||
            (e.ctrlKey && e.code === 'F5') ||
            (e.ctrlKey && e.keyCode == 116)
        ) {
            localStorage.removeItem(languageKey);
            localStorage.removeItem(countryKey);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        const isNutritionCrop = location?.pathname?.startsWith(ROUTES.cropNutritionPrograms + '/');
        const isTrialCrop = location?.pathname?.startsWith(ROUTES.trialResults + '/');
        if (!isNutritionCrop && !isTrialCrop) {
            dispatch(setLastLocation({ pathname: location?.pathname }));
        }
    }, [location]);

    const start = async () => {
        // @ts-ignore
        await dispatch(fetchCountries());
        if (country_code) {
            // @ts-ignore
            await dispatch(fetchLanguages(country_code));
            // @ts-ignore
            dispatch(fetchTranslations());
            // @ts-ignore
            dispatch(fetchLatestPostsForHomepage({ limit: homepage_posts_limit }));
            // @ts-ignore
            dispatch(fetchMainProducts());
            // @ts-ignore
            dispatch(fetchHomepage());
        }
    };

    useTimeout(() => {
        setLoading(false);
    }, delay);

    useEffect(() => {
        start();
    }, [dispatch, country_code]);

    if (
        translationsIslLoading ||
        homepageIsLoading ||
        loading ||
        latestPostHomepageIsLoading ||
        languagesAndCountriesIsLoading
    ) {
        return <Preloader main />;
    }

    return (
        <>
            <Routes>
                <Route path={ROUTES.base} element={<MainLayout />}>
                    <Route path={ROUTES.base} element={<Homepage />} />
                    <Route path={ROUTES.products} element={<Products />} />
                    <Route path={ROUTES.products + '/:slug'} element={<AboutProducts />} />
                    <Route path={ROUTES.resources} element={<Resources />} />
                    <Route path={ROUTES.trialResults} element={<TrialResults />} />
                    <Route path={ROUTES.trialResults + '/:slug'} element={<DetailedTrialResults />} />
                    <Route path={ROUTES.cropNutritionPrograms} element={<CropNutritionPrograms />} />
                    <Route path={ROUTES.cropNutritionPrograms + '/:slug'} element={<DetailedOfCropNutrition />} />
                    <Route path={ROUTES.informationMaterials} element={<InformationMaterials />} />
                    <Route path={ROUTES.aboutUs} element={<AboutUs />} />
                    <Route path={ROUTES.news} element={<News />} />
                    <Route path={ROUTES.news + '/:slug'} element={<Article />} />
                    <Route path={ROUTES.career} element={<Career />} />
                    <Route path={ROUTES.career + '/:slug'} element={<DetailedJob />} />
                    <Route path={ROUTES.termsAndConditions} element={<TermsAndConditions />} />
                    <Route path={ROUTES.legalInfo} element={<LegalInfo />} />
                    <Route path={ROUTES.contacts} element={<Contacts />} />
                    <Route path="*" element={<Page404 />} />
                </Route>
            </Routes>
        </>
    );
};

export default App;
