import Lottie from 'react-lottie-player';
import lottieJson from './Preloader.json';
import lottieJsonX from './Preloader X.json';
import './styles.scss';

const Preloader = ({ main = false }) => {
    return main ? (
        <Lottie
            loop={false}
            animationData={lottieJson}
            play
            speed={1}
            className="preloader"
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
        />
    ) : (
        <Lottie
            loop
            animationData={lottieJsonX}
            play
            className="preloader"
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
        />
    );
};

export default Preloader;
