import WithSidebarLayout from 'Layout/WithSidebar';
import './styles.scss';

const FormSection = ({ sidebar, children, className = '' }) => (
    <section className={`container form-section ${className}`}>
        <WithSidebarLayout Sidebar={sidebar} breakpoint="lg">
            {children}
        </WithSidebarLayout>
    </section>
);

export default FormSection;
