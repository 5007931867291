import TextWithIcon from 'components/TextWithIcon';
import JobLabels from 'components/JobLabels';
import { Link } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { ReactComponent as Arrow } from 'assets/icons/arrow-right.svg';

const JobCard = ({ item }) => {
    const { slug, title, locations, experience, work_type, created_at, preview_description } = item;

    return (
        <Link to={`${ROUTES.career}/${slug}`} className="job-card">
            <div className="job-card__title">
                <span>{title}</span>
                <Arrow />
            </div>
            <div className="job-card__description opacity-70 body1">{preview_description}</div>
            <JobLabels experience={experience} locations={locations} work_type={work_type} />
            <TextWithIcon iconName="calendar" text={created_at} />
        </Link>
    );
};

export default JobCard;
