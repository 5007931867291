import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/reset.css';
import './styles/common.css';
import './styles/main.scss';
import { store } from 'store/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
import ScrollToTop from 'hooks/scrollToTop';
import CookieBanner from 'components/CookieBanner';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'styles/theme';

ReactDOM.createRoot(document.getElementById('root')).render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <ScrollToTop />
                <App />
                <CookieBanner />
            </BrowserRouter>
        </ThemeProvider>
    </Provider>,
);
