import { createSelector, createSlice } from '@reduxjs/toolkit';
import { getResources } from 'api/Resources';
import { fetchWithLanguage } from 'store/helpers';

export const fetchResources = fetchWithLanguage('fetchResources', getResources, (_, { getState }) => {
    const { data } = getState().resourcesState.resources;
    return !data;
});

const resourcesSlice = createSlice({
    name: 'resourcesSlice',
    initialState: {
        resources: {},
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchResources.fulfilled, (state, action) => {
            state.resources = action.payload;
        });
    },
});

export const selectResourcesPage = createSelector(
    [state => state.resourcesState.resources],
    resources => resources.data || {},
);

export const selectResourcesPageIsLoading = createSelector(
    [state => state.resourcesState.resources],
    resources => resources?.status !== 'success',
);

export default resourcesSlice.reducer;
