import './styles.scss';

const NavigationItem = ({ index, activeIndex, text, onClick }) => (
    <div
        className={`navigation-sidebar__item ${index === activeIndex ? 'navigation-sidebar__active' : ''}`}
        onClick={onClick}
    >
        <p className="button-text">{text}</p>
    </div>
);

const NavigationSideBar = ({ items, activeIndex, refs, includesTitle = '' }) => {
    return (
        <div className="navigation-sidebar">
            {items.map((text, index) => {
                const handleClick = () => {
                    window.scrollTo({
                        top: refs[index].current.getBoundingClientRect().top + window.scrollY - 100,
                        behavior: 'smooth',
                    });
                };
                if (text === includesTitle) {
                    return false;
                }
                return (
                    <NavigationItem
                        key={index}
                        index={index}
                        activeIndex={activeIndex}
                        text={text}
                        onClick={handleClick}
                    />
                );
            })}
        </div>
    );
};

export default NavigationSideBar;
