import useOnScreen, { range } from 'hooks/useOnScreen';
import React from 'react';

const NavigationSectionWrapper = ({ children, index, setData }) => {
    const ref = React.useRef(null);
    const options = { threshold: range(0, 1, 0.01, 2) };
    const { ratio } = useOnScreen(ref, { ...options });

    React.useEffect(() => {
        setData(prevState => ({ ...prevState, [index]: ratio }));
    }, [index, ratio, setData]);

    return <div ref={ref}>{children}</div>;
};

export default NavigationSectionWrapper;
