import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
    getFilteredInformationMaterials,
    getInformationMaterialFlyers,
    getInformationMaterials,
} from 'api/InformationMaterials';
import { fetchWithLanguage } from 'store/helpers';
import { getDataWithReplacedFields } from 'utils';

export const fetchInformationMaterials = fetchWithLanguage('fetchInformationMaterials', getInformationMaterials);
export const fetchInformationMaterialsFlyers = fetchWithLanguage(
    'fetchInformationMaterialsFlyers',
    getInformationMaterialFlyers,
);

export const fetchFilteredInformationMaterials = fetchWithLanguage(
    'fetchFilteredInformationMaterials',
    getFilteredInformationMaterials,
);

const informationMaterialsSlice = createSlice({
    name: 'InformationMaterialsSlice',
    initialState: {
        informationMaterials: {},
        informationMaterialFlyers: {},
        informationMaterialFlyersIsLoading: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchFilteredInformationMaterials.fulfilled, (state, action) => {
                state.informationMaterials.data.catalogs = action.payload.data;
            })
            .addCase(fetchInformationMaterials.fulfilled, (state, action) => {
                state.informationMaterials = action.payload;
                const data = action.payload.data.catalogs
                    .filter(catalog => catalog.has_flyers)
                    .reduce(
                        (prev, catalog) => ({
                            ...prev,
                            [catalog.id]: {
                                flyers: [],
                                next: false,
                            },
                        }),
                        {},
                    );
                state.informationMaterialFlyers = data;
            })
            .addCase(fetchInformationMaterialsFlyers.pending, (state, action) => {
                state.informationMaterialFlyersIsLoading = true;
            })
            .addCase(fetchInformationMaterialsFlyers.fulfilled, (state, action) => {
                const { payload, meta } = action;

                if (meta.arg.page === 1) {
                    state.informationMaterialFlyers[meta.arg.id] = {
                        flyers: payload.data,
                        page: meta.arg.page,
                        next: payload.next,
                    };
                } else {
                    state.informationMaterialFlyers[meta.arg.id] = {
                        flyers: [...state.informationMaterialFlyers[meta.arg.id].flyers, ...payload.data],
                        page: meta.arg.page,
                        next: payload.next,
                    };
                }
                state.informationMaterialFlyersIsLoading = false;
            });
    },
});

export const selectInformationMaterialsPage = createSelector(
    [state => state.informationMaterialsState.informationMaterials],
    informationMaterials => getDataWithReplacedFields(informationMaterials, {}, 'selectInformationMaterialsPage') || {},
);
export const selectInformationMaterialsPageIsLoading = createSelector(
    [state => state.informationMaterialsState.informationMaterials],
    informationMaterials => informationMaterials?.status !== 'success',
);

export const selectInformationMaterialsFlyersIsLoading = createSelector(
    [state => state.informationMaterialsState.informationMaterialFlyersIsLoading],
    informationMaterialFlyersIsLoading => informationMaterialFlyersIsLoading,
);

export const selectInformationMaterialFlyers = createSelector(
    [state => state.informationMaterialsState.informationMaterialFlyers],
    informationMaterialFlyers => informationMaterialFlyers || {},
);

export default informationMaterialsSlice.reducer;
