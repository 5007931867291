import { Slider, Slide } from 'components/Slider/Slider';
import { Button } from 'components/buttons/Button/Button';
import HeroNewsCard from 'pages/Homepage/Hero/HeroNewsCard';
import 'swiper/css';
import './styles.scss';
import GradientText from 'components/GradientText';
import { useSelector } from 'react-redux';
import { selectHomepage } from 'store/slices/HomepageSlice';
import { selectLatestPostHomepage } from 'store/slices/BlogSlice';
import { useEffect, useRef } from 'react';
import { scrollToSection } from 'helpers';

const Hero = () => {
    const { first_screen_title, banner_title, fs_title_background } = useSelector(selectHomepage);
    const posts = useSelector(selectLatestPostHomepage);
    const videoEl = useRef(null);

    const attemptPlay = () => {
        videoEl &&
            videoEl.current &&
            videoEl.current.play().catch(error => {
                console.error('Error attempting to play', error);
            });
    };

    useEffect(() => {
        attemptPlay();
    }, []);

    const handleClick = () => scrollToSection('solutions', 100);

    return (
        <section className="container">
            <div className="hero">
                <video playsInline loop muted src={fs_title_background} ref={videoEl} />
                <GradientText textColor="white" className="has-animation">
                    <h1 dangerouslySetInnerHTML={{ __html: first_screen_title }} />
                </GradientText>
            </div>
            <div className="information-wrapper">
                <div className="information-wrapper__offer-section">
                    <GradientText size="super-small" textColor="white" className="has-animation">
                        <span dangerouslySetInnerHTML={{ __html: banner_title }} />
                    </GradientText>
                    <Button type="white-arrow" onClick={handleClick} />
                </div>
                <div className="information-wrapper__box-for-border"></div>
                <div className="information-wrapper__box-for-border radius16"></div>
                <div className="information-wrapper__box-for-border location-left"></div>
                <div className="information-wrapper__box-for-border location-left radius16"></div>
                <div className="information-wrapper__news-wrapper">
                    <Slider className="slider-head">
                        {posts?.map(post => (
                            <Slide key={post.slug}>
                                <HeroNewsCard card={post} array={posts} />
                            </Slide>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default Hero;
