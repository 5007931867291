import axios from 'axios';
import config from 'api/urls-config.json';
import { API, flyers_limit } from 'api/constants';

export const getInformationMaterials = params => {
    return axios.get(`${API + config.pages.information_materials}`, { params });
};

export const getInformationMaterialFlyers = params => {
    const { id, language, country, page = 1, limit = flyers_limit } = params;

    return axios.get(`${API + config.pages.information_materials + '/' + id + '/flyers'}`, {
        params: {
            language,
            country,
            page,
            limit,
        },
    });
};

export const getFilteredInformationMaterials = params => {
    return axios.get(`${API + config.catalogs}`, { params });
};
