import Banner from 'components/Banner';
import { LabelButton } from 'components/buttons/LabelButton/LabelButton';
import GradientText from 'components/GradientText';
import headOfficePin from 'images/icons/headOfficePin.svg';
import './styles.scss';
import TeamSection from './TeamSection';
import MissionAndValuesSection from 'components/MissionAndValuesSection';
import OurHistory from './OurHistory';
import DefaultForm from 'components/FormSection/Forms/DefaultForm';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchAboutUs,
    selectAboutUsPage,
    selectAboutUsPageIsLoading,
    selectEmployeesIsLoading,
    fetchEmployees,
} from 'store/slices/AboutUsSlice';
import React, { useRef } from 'react';
import { useAnimation } from 'hooks/useAnimation';
import Preloader from 'Layout/Preloader';
import { scrollToSection } from 'helpers';

const AboutUs = () => {
    const dispatch = useDispatch();
    const loading = useSelector(selectAboutUsPageIsLoading);
    const employeesIsLoading = useSelector(selectEmployeesIsLoading);
    const [page, setPage] = React.useState(1);
    const handleShowMore = () => setPage(page + 1);

    const {
        fs_title,
        fs_background,
        fs_description,
        about_tag,
        about_title,
        about_text,
        about_card_text,
        about_card_image,
        mission_cards,
        history_title,
        histories_cards,
        histories_image,
        mission_background_image,
        mission_title,
    } = useSelector(selectAboutUsPage);

    React.useEffect(() => {
        // @ts-ignore
        dispatch(fetchAboutUs());
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(
            // @ts-ignore
            fetchEmployees({ page }),
        );
    }, [dispatch, page]);

    const handleClick = () => scrollToSection('this', 100);

    const container = useRef();

    useAnimation({
        items: ['.team', '.our-history-cards'],
        container,
        dependencies: [loading, employeesIsLoading],
    });

    if (loading || employeesIsLoading) return <Preloader />;

    return (
        <div ref={container}>
            <Banner image={fs_background} buttonText={fs_description} onClickButton={handleClick}>
                <GradientText size="middle" textColor="white">
                    <span dangerouslySetInnerHTML={{ __html: fs_title }} />
                </GradientText>
            </Banner>

            <section className="container this">
                <div className="inner-container type-2">
                    <div className="main-text-container">
                        <LabelButton>{about_tag}</LabelButton>
                        <GradientText className="has-animation">
                            <span dangerouslySetInnerHTML={{ __html: about_title }} />
                        </GradientText>
                        <div className="offset-left-4 max-width-760">
                            <GradientText size="small" className="has-animation">
                                <span dangerouslySetInnerHTML={{ __html: about_text }} />
                            </GradientText>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container">
                <div className="inner-container type-2">
                    <div className="head-office has-animation">
                        <div className="head-office__left">
                            <img src={headOfficePin} alt="icon pin" />
                            <GradientText size="super-small">
                                <span dangerouslySetInnerHTML={{ __html: about_card_text }} />
                            </GradientText>
                        </div>
                        <div className="head-office__right">
                            <img src={about_card_image} alt="head office" />
                        </div>
                    </div>
                </div>
            </section>

            <MissionAndValuesSection
                loading={loading}
                background={mission_background_image}
                title={mission_title}
                data={mission_cards}
            />

            <TeamSection showMore={handleShowMore} />

            <OurHistory title={history_title} cards={histories_cards} />

            {histories_image && (
                <section className="container about-us-photo">
                    <img src={histories_image} alt="" loading="eager" />
                </section>
            )}

            <DefaultForm />
        </div>
    );
};
export default AboutUs;
