import { getIcon } from './helpers';
import './styles.scss';

const JobLabels = ({ experience, locations, work_type }) => {
    const labels = [
        { type: 'experience', text: experience },
        { type: 'locations', text: locations },
        { type: 'work_type', text: work_type },
    ].filter(item => !!item.text);

    return (
        !!labels.length && (
            <div className="job-labels">
                {labels.map((label, index) => (
                    <div key={index} className="job-label body3">
                        {getIcon(label.type)}
                        <div>{label.text}</div>
                    </div>
                ))}
            </div>
        )
    );
};

export default JobLabels;
