import { useLocation } from 'react-router-dom';
import home from 'images/icons/whiteIcons/home.svg';
import './styles.scss';
import BreadcrumbItem from './BreadcrumbItem';
import { ROUTES } from 'constants/routes';
import { useSelector } from 'react-redux';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import { selectProductContent } from 'store/slices/ProductsSlice';
import { selectCropNutritionDetail } from 'store/slices/CropNutritionSlice';
import { selectDetailTrialResults } from 'store/slices/TrialResults';

const Breadcrumbs = () => {
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');

    const {
        menu_products_text,
        menu_resources_text,
        menu_contacts_text,
        menu_about_text,
        menu_news_text,
        menu_career_text,
        menu_crop_nutrition_text,
        menu_trial_results_text,
        menu_information_materials_text,
        footer_terms_conditions_text,
        footer_legal_info_text,
    } = useSelector(selectTranslations);

    const texts = {
        [ROUTES.products]: menu_products_text,
        [ROUTES.resources]: menu_resources_text,
        '/crop-nutrition-programs': menu_crop_nutrition_text,
        '/trial-results': menu_trial_results_text,
        '/information-materials': menu_information_materials_text,
        [ROUTES.news]: menu_news_text,
        [ROUTES.career]: menu_career_text,
        [ROUTES.aboutUs]: menu_about_text,
        [ROUTES.termsAndConditions]: footer_terms_conditions_text,
        [ROUTES.legalInfo]: footer_legal_info_text,
        [ROUTES.contacts]: menu_contacts_text,
    };
    const product_name = useSelector(selectProductContent).title;
    const nutr_crop_name = useSelector(selectCropNutritionDetail).title;
    const trial_crop_name = useSelector(selectDetailTrialResults).title;

    const isProduct = pathSegments[0] === 'products' && !!pathSegments[1];
    const isDetailCrop =
        pathSegments[0] === 'resources' && pathSegments[1] === 'crop-nutrition-programs' && !!pathSegments[2];
    const isTrialRes = pathSegments[0] === 'resources' && pathSegments[1] === 'trial-results' && !!pathSegments[2];

    const getName = segment => {
        if (isProduct && pathSegments[1] === segment) return product_name;
        if (isDetailCrop && pathSegments[2] === segment) return nutr_crop_name;
        if (isTrialRes && pathSegments[2] === segment) return trial_crop_name;
        return texts[`/${segment}`];
    };

    return (
        <div className="breadcrumbs">
            <BreadcrumbItem to="/">
                <img src={home} alt="home" />
            </BreadcrumbItem>
            <div className="breadcrumbs-dot"></div>
            {pathSegments.map((segment, index) => (
                <span key={segment} className="breadcrumbs-span">
                    <BreadcrumbItem to={`/${pathSegments.slice(0, index + 1).join('/')}`} className="body3">
                        {getName(segment)}
                    </BreadcrumbItem>
                    {index < pathSegments.length - 1 && <div className="breadcrumbs-dot margin-left"></div>}
                </span>
            ))}
        </div>
    );
};

export default Breadcrumbs;
