import { createSlice, createSelector } from '@reduxjs/toolkit';
import { getHomepage } from 'api/Homepage';
import { fetchWithLanguage } from 'store/helpers';

export const fetchHomepage = fetchWithLanguage('fetchHomepage', getHomepage);

const homepageSlice = createSlice({
    name: 'homepageSlice',
    initialState: {
        homepage: {},
        homepageIsLoading: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchHomepage.pending, state => {
            state.homepageIsLoading = true;
        });
        builder.addCase(fetchHomepage.fulfilled, (state, action) => {
            state.homepageIsLoading = false;
            state.homepage = action.payload;
        });
    },
});

export const selectHomepage = createSelector([state => state.homepageState.homepage], homepage => homepage.data || {});
export const selectHomepageIsLoading = createSelector(
    [state => state.homepageState.homepageIsLoading],
    homepageIsLoading => homepageIsLoading,
);

export default homepageSlice.reducer;
