export const type_nutrition = 'nutrition';
export const type_trials = 'trials';
export const product_items_per_request = 8;
export const posts_per_request = 9;
export const homepage_posts_limit = 4;
export const vacancies_list_limit = 4;
export const flyers_limit = 6;

// export const API = 'https://api-dev-uniferx.goodface.com.ua/api'; // DEV
// export const API = 'https://api-rc-uniferx.goodface.com.ua/api'; // RC
// export const API = 'https://admin.new.uniferx.com/api'; // OLD PROD

export const API = 'https://admin.uniferx.com/api'; // PROD
// export const API = 'https://dev.admin.uniferx.com/api'; // DEV
