const BenefitCard = ({ item, index, withCustomContent }) => {
    const { title, description, type } = item;

    const content = withCustomContent ? (
        <div dangerouslySetInnerHTML={{ __html: item.description }} />
    ) : (
        <>
            {title && <div className="description-demibold benefit-card__title">{title}</div>}
            <div className="body1 opacity-80 benefit-card__text">{description}</div>
        </>
    );
    return (
        <div key={title} className={`benefit-card ${type ? `benefit-card__${type}` : ''}`}>
            <div className="gradient-number dark">
                <span>{index + 1}</span>
            </div>
            {content}
        </div>
    );
};

export default BenefitCard;
