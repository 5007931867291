import { SolutionCard } from 'components/SolutionCard/SolutionCard';
import './styles.scss';
import GradientText from 'components/GradientText';
import { useSelector } from 'react-redux';
import { selectHomepage } from 'store/slices/HomepageSlice';
import { useRef } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import useMediaQuery from 'hooks/useMediaQuery';

gsap.registerPlugin(ScrollTrigger);

const Solutions = () => {
    const { banner_cards = [], banner_title } = useSelector(selectHomepage);
    const container = useRef();
    const isTablet = useMediaQuery('max-width: 1023px');

    const speed = isTablet ? [-250, -180, -220, -270] : [-300, -200, -150, -270];

    useGSAP(
        () => {
            let targets = gsap.utils.toArray(['.solution-card']);
            targets.forEach((item, index) => {
                gsap.to(item, {
                    yPercent: speed[index],
                    ease: 'none',
                    scrollTrigger: {
                        trigger: '.solutions',
                        scrub: 1,
                    },
                });
            });
        },
        { scope: container },
    );

    return (
        <section id="information-wrapper" className="container" ref={container}>
            <div className="solutions">
                <GradientText size="super-small" textColor="white" className="has-animation">
                    <span dangerouslySetInnerHTML={{ __html: banner_title }} />
                </GradientText>
                <div>
                    {banner_cards.map(item => (
                        <SolutionCard key={item.title} image={item.icon} numbers={item.title} description={item.text} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Solutions;
