export const convertNodeToJSX = (node) => {
  if (node.nodeType === Node.TEXT_NODE) {
    return node.textContent;
  }

  const children = Array.from(node.childNodes).map((childNode, index) =>
    convertNodeToJSX(childNode)
  );

  switch (node.tagName) {
    case 'SPAN':
      return <span key={node.textContent + Math.random()}>{children}</span>;
    case 'SUB':
      return <sub key={node.textContent + Math.random()}>{children}</sub>;
    case 'SUP':
      return <sup key={node.textContent + Math.random()}>{children}</sup>;
    default:
      return <>{children}</>;
  }
}