import NewsCard from 'components/NewsCards/NewsCard';
import { LabelButton } from 'components/buttons/LabelButton/LabelButton';
import { Button } from 'components/buttons/Button/Button';
import './styles.scss';
import useMediaQuery from 'hooks/useMediaQuery';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import React from 'react';
import { useSelector } from 'react-redux';
import GradientText from 'components/GradientText';
import WithSidebarLayout from 'Layout/WithSidebar';
import { ROUTES } from 'constants/routes';
import { selectHomepage } from 'store/slices/HomepageSlice';
import { selectLatestPostHomepage } from 'store/slices/BlogSlice';

const LatestNews = () => {
    const { news_button, news_description, news_tag, news_title } = useSelector(selectHomepage);
    const posts = useSelector(selectLatestPostHomepage);

    const modules = [Pagination];

    const breakpoints = {
        320: {
            slidesPerView: 1,
        },
        768: {
            slidesPerView: 2,
        },
    };
    const isTablet = useMediaQuery('max-width: 1023px');

    return (
        <section className="container">
            <div className="inner-container type-3">
                <div className="main-text-container with-absolute-label">
                    <LabelButton>{news_tag}</LabelButton>
                    <div className="offset-left-4">
                        <GradientText size="middle" className="has-animation">
                            <h2>{news_title}</h2>
                        </GradientText>
                    </div>
                </div>
            </div>
            <WithSidebarLayout
                containerType="type-2"
                Sidebar={
                    <div className="homepage-news-text has-animation">
                        <GradientText size="super-small">
                            <span dangerouslySetInnerHTML={{ __html: news_description }} />
                        </GradientText>
                        <Button href={ROUTES.news} type="learn-more-arrow">
                            {news_button}
                        </Button>
                    </div>
                }
            >
                {isTablet ? (
                    <div className="news-slider homepage-news-slider has-animation">
                        <Swiper pagination modules={modules} breakpoints={breakpoints} spaceBetween={8}>
                            {posts.map(card => (
                                <SwiperSlide key={card.slug}>
                                    <NewsCard data={card} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                ) : (
                    <div className="latest-cards-container has-animation">
                        {posts.map(card => (
                            <NewsCard key={card.slug} data={card} />
                        ))}
                    </div>
                )}
            </WithSidebarLayout>
        </section>
    );
};
export default LatestNews;
