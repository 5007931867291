import './styles.scss';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { COUNTRIES, getCodeWithChangedUkraine } from './constants';
import { FormControl, FormLabel, InputAdornment } from '@mui/material';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import icon from 'images/Globe.svg';

const Flag = ({ code }) => {
    if (code === 'en') {
        return <img loading="lazy" width="20" src={icon} alt="" />;
    }
    return (
        <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${getCodeWithChangedUkraine(code)}.png 2x`}
            src={`https://flagcdn.com/w20/${getCodeWithChangedUkraine(code)}.png`}
            alt=""
        />
    );
};

export default function CountrySelect({ value, onChange, withoutInternational = false }) {
    const [countryFlag, setCountryFlag] = useState(value);
    const { menu_choose_country_text } = useSelector(selectTranslations);

    const handleChange = (event, newValue) => {
        onChange(newValue);
        setCountryFlag(newValue);
    };

    const options = withoutInternational ? COUNTRIES.filter(item => item.code !== 'en') : COUNTRIES;

    return (
        <FormControl fullWidth className="material-dropdown">
            <FormLabel className="material-dropdown__label" focused={false}>
                {menu_choose_country_text}
                {withoutInternational && '*'}
            </FormLabel>
            <Autocomplete
                // open={true}
                // onOpen={() => {}}
                value={value}
                onChange={handleChange}
                options={options}
                autoHighlight
                disablePortal
                disableClearable
                isOptionEqualToValue={(option, value) => option.code === value.code}
                getOptionLabel={option => option.name}
                renderOption={(props, option) => {
                    const { key, ...optionProps } = props;
                    return (
                        <Box key={key} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...optionProps}>
                            <Flag code={option.code} />
                            {option.name}
                        </Box>
                    );
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        focused={false}
                        sx={{
                            backgroundColor: 'rgba(255, 255, 255, 0.12)',
                        }}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    {countryFlag?.code && <Flag code={countryFlag.code} />}
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <>
                                    <div className="material-dropdown__chevron"></div>
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                            // endAdornment: (
                            //     <InputAdornment className="material-dropdown__chevron" position="end"></InputAdornment>
                            // ),
                        }}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </FormControl>
    );
}
