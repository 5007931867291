import { getCountry } from 'components/ContryAutocompleteDropdown/constants';
import './styles.scss';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsDeterminateOpen, setCountryOpen, setDeterminateClose } from 'store/slices/UI-Slice';
import { getLanguages } from 'api/Languages';
import { changeCountry, changeLanguage } from 'store/slices/languageSlice';
import { setDeterminatePopupIsShowed } from 'store/helpers';
import { fetchPopupTranslations, selectPopupTranslations } from 'store/slices/GeneralTranslationsSlice';

const getNoTranslation = (code, word) => {
    if (code === 'uk') return 'Ні';
    return word || 'No';
};

const DetermineCountryPopup = () => {
    const dispatch = useDispatch();
    const isOpenFromCookie = useSelector(selectIsDeterminateOpen);
    const { declined, show, success, title } = useSelector(selectPopupTranslations);
    const { country, code, language } = getCountry();

    useEffect(() => {
        if (isOpenFromCookie) {
            // @ts-ignore
            dispatch(fetchPopupTranslations({ country: language }));
        }
    }, []);

    const handleYesClick = () => {
        const countryData = { code, name: country };
        getLanguages(code)
            .then(response => {
                const language = response.data.data[0];
                dispatch(changeLanguage(language));
                dispatch(changeCountry(countryData));
                setDeterminatePopupIsShowed();
            })
            .finally(() => {
                window.location.href = '/';
            });
    };
    const handleNoClick = () => {
        dispatch(setDeterminateClose());
        dispatch(setCountryOpen(true));
    };

    return (
        <div className="determinate-container">
            {isOpenFromCookie && country && show && (
                <div className="determinate">
                    <div className="determinate__title">{title || 'Are you from this country?'}</div>
                    <div className="determinate__country">{country}</div>
                    <div className="determinate__buttons">
                        <button className="button outlined" onClick={handleNoClick}>
                            {getNoTranslation(code, declined)}
                        </button>
                        <button className="button" onClick={handleYesClick}>
                            {success || 'Yes'}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DetermineCountryPopup;
