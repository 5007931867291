import { useSelector } from 'react-redux';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';

const ButtonShowMore = ({ text = '', onClick, next }) => {
    const { other_show_more_button } = useSelector(selectTranslations);

    return (
        next && (
            <button className="button show-more full-width has-children" onClick={onClick}>
                {text || other_show_more_button}
            </button>
        )
    );
};

export default ButtonShowMore;
