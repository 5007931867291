import './styles.scss';
import React from 'react';
import TeamMemberCard from './TeamMemberCard';
import TeamCareerCard from './TeamCareerCard';
import TeamInfoCard from './TeamInfoCard';
import { useSelector } from 'react-redux';
import { selectEmployees, selectAboutUsPage } from 'store/slices/AboutUsSlice';
import ButtonShowMore from 'components/Button/ButtonShowMore';

const TeamsCards = ({ showMore }) => {
    const { team_show_more } = useSelector(selectAboutUsPage);
    const { data = [], next } = useSelector(selectEmployees);
    return (
        <>
            <div className="team">
                {data.map((item, index) =>
                    item.type === 'info' ? (
                        <TeamInfoCard key={index} data={item} />
                    ) : (
                        <TeamMemberCard key={index} data={item} />
                    ),
                )}
                <TeamCareerCard />
            </div>
            <ButtonShowMore next={next} text={team_show_more} onClick={showMore} />
        </>
    );
};

export default TeamsCards;
