import { getIcon } from './helpers';
import './styles.scss';

const TextWithIcon = ({ iconName, text }) =>
    text && (
        <div className="text-with-icon body3">
            <div className="text-with-icon__icon">{getIcon(iconName)}</div>
            <div className="text-with-icon__text">{text}</div>
        </div>
    );

export default TextWithIcon;
