import { createSelector, createSlice } from '@reduxjs/toolkit';
import { getSearch } from 'api/Search';
import { fetchWithLanguage } from 'store/helpers';

export const fetchSearch = fetchWithLanguage('fetchSearch', getSearch);

const searchData = {
    data: {
        products: [],
        cropsNutrition: [],
        cropsTrials: [],
        posts: [],
        catalogs: [],
        vacancies: [],
        flyers: [],
    },
};

const searchSlice = createSlice({
    name: 'searchSlice',
    initialState: {
        searchData,
        isSearchEnd: true,
    },
    reducers: {
        resetSearch: state => {
            state.searchData = searchData;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchSearch.pending, state => {
                state.isSearchEnd = false;
            })
            .addCase(fetchSearch.fulfilled, (state, action) => {
                state.isSearchEnd = true;
                state.searchData = action.payload;
            });
    },
});

export const selectSearchData = createSelector(
    [state => state.searchState.searchData],
    searchData => searchData.data || {},
);

export const selectIsSearchEnd = createSelector([state => state.searchState.isSearchEnd], isSearchEnd => isSearchEnd);

export const { resetSearch } = searchSlice.actions;

export default searchSlice.reducer;
