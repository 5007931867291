import { Button } from 'components/buttons/Button/Button';
import HeaderNavigationCard from './HeaderNavigationCard';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const HeaderNavigationDropdown = ({ items, button }) => {
    const location = useLocation();
    const [isForseClosed, setForseClosed] = useState(false);

    useEffect(() => {
        setForseClosed(true);
        const timeoutId = setTimeout(() => {
            setForseClosed(false);
        }, 50);
        return () => clearTimeout(timeoutId);
    }, [location]);

    const style = isForseClosed ? { display: 'none' } : null;

    return (
        <div className="navigation-item__dropdown" style={style}>
            <div className="navigation-item__dropdown-items">
                {items.map(item => (
                    <HeaderNavigationCard key={item.link} item={item} />
                ))}
            </div>
            {button && (
                <div className="navigation-item__dropdown-button">
                    <Button href={button.link} type="learn-more-arrow white-text">
                        {button.name}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default HeaderNavigationDropdown;
