import axios from 'axios';
import config from 'api/urls-config.json';
import { API, vacancies_list_limit } from 'api/constants';

export const getCareer = params => {
    return axios.get(`${API + config.pages.career}`, { params });
};

export const getVacancies = params => {
    const { language, country, page = 1, limit = vacancies_list_limit } = params;

    return axios.get(`${API + config.vacancies_list}`, {
        params: {
            language,
            country,
            page,
            limit,
        },
    });
};

export const getVacancy = params => {
    const { slug, language, country } = params;

    return axios.get(`${API + config.pages.vacancy_slug + slug}`, {
        params: {
            language,
            country,
        },
    });
};
