import './labelButton.css';

export const LabelButton = ({ children }) => {
    return (
        <div className="label-button body3">
            <span>
                <div></div>
                {children}
            </span>
        </div>
    );
};
