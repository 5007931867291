import Banner from 'components/Banner';
import CareerForm from 'components/FormSection/Forms/CareerForm';
import JobLabels from 'components/JobLabels';
import TextWithIcon from 'components/TextWithIcon';
import React, { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVacancy, selectVacancyContent, selectVacancyIsLoading } from 'store/slices/CareerSlice';
import Preloader from 'Layout/Preloader';
import { useAnimation } from 'hooks/useAnimation';
import { scrollToSection, with404Page } from 'helpers';

const DetailedJob = () => {
    const { slug } = useParams();
    const dispatch = useDispatch();
    const content = useSelector(selectVacancyContent);
    const loading = useSelector(selectVacancyIsLoading);
    const navigate = useNavigate();
    React.useEffect(() => {
        with404Page(
            // @ts-ignore
            dispatch(fetchVacancy({ slug })),
            navigate,
        );
    }, [dispatch, slug]);

    const { title, data, experience, locations, work_type, title_background, created_at, fs_button } = content;

    const handleClick = () => scrollToSection('form-section', 100);

    const container = useRef();

    useAnimation({
        items: ['.article-content'],
        container,
        dependencies: [loading],
    });

    if (loading) return <Preloader />;

    return (
        <div ref={container}>
            <Banner image={title_background} buttonText={fs_button} onClickButton={handleClick}>
                <h2>{title}</h2>
                <JobLabels experience={experience} locations={locations} work_type={work_type} />
                <TextWithIcon iconName="calendar" text={created_at} />
            </Banner>

            <article className="article-content" dangerouslySetInnerHTML={{ __html: data }} />

            <CareerForm />
        </div>
    );
};

export default DetailedJob;
