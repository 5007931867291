import './styles.scss';

export const SolutionCard = ({ image, numbers, description }) => {
    return (
        <div className="solution-card">
            <img src={image} alt={image} />
            <div className="solution-card__title">{numbers}</div>
            <p className="body1">{description}</p>
        </div>
    );
};
