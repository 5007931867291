import axios from 'axios';
import config from 'api/urls-config.json';
import { API } from 'api/constants';

export const getSearch = params => {
    const { search, language, country } = params;

    return axios.get(`${API + config.search}`, {
        params: {
            language,
            country,
            search,
        },
    });
};
