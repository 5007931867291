import { useState } from 'react';
import './styles.scss';
import { useSelector } from 'react-redux';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import FormControlInput from 'components/FormControls/Input';
import FormControlsTextArea from 'components/FormControls/TextArea';
import Checkbox from 'components/Checkbox';
import ButtonSubmit from 'components/Button/ButtonSubmit';
import { useLocation } from 'react-router-dom';
import { ThankYouPage } from './ThankYouPage';
import axios from 'axios';
import config from 'api/urls-config.json';
import InputFile from 'components/FormControls/InputFile';
import { API } from 'api/constants';

export const CareerForm = () => {
    const { pathname } = useLocation();
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [agreed, setAgreed] = useState(false);
    const [message, setMessage] = useState('');
    const [file, setFile] = useState(null);
    const [inputsValues, setInputsValues] = useState({
        name: '',
        email: '',
        phone: '',
        last_name: '',
    });

    const {
        cta_field_email_placeholder,
        cta_field_email_title,
        cta_field_message_placeholder,
        cta_field_message_title,
        cta_field_name_placeholder,
        cta_field_name_title,
        cta_field_phone_placeholder,
        cta_field_phone_title,
        cta_field_file_title,
        cta_field_file_placeholder,
        cta_policy_careers_text,
        cta_field_last_name_title,
        cta_field_last_name_placeholder,
    } = useSelector(selectTranslations);

    const inputs = [
        {
            name: 'name',
            required: true,
            label: cta_field_name_title,
            placeholder: cta_field_name_placeholder,
        },
        {
            name: 'last_name',
            required: true,
            label: cta_field_last_name_title,
            placeholder: cta_field_last_name_placeholder,
        },
        {
            name: 'email',
            required: true,
            label: cta_field_email_title,
            placeholder: cta_field_email_placeholder,
        },
        {
            name: 'phone',
            label: cta_field_phone_title,
            placeholder: cta_field_phone_placeholder,
        },
    ];

    const handleTextareaChange = message => setMessage(message);
    const handleCheckboxChange = () => setAgreed(!agreed);
    const handleSubmit = () => {
        if (!disabled) {
            setLoading(true);

            axios
                .postForm(`${API + config.form}`, {
                    ...inputsValues,
                    message,
                    from_page: pathname,
                    file,
                })
                .then(response => {
                    setStatus(response.data.status);
                    setLoading(false);
                })
                .catch(function (error) {
                    console.log(error);
                    setLoading(false);
                });
        }
    };

    const disabled =
        !agreed || inputsValues.name === '' || inputsValues.last_name === '' || inputsValues.email === '' || loading;

    return status === 'success' ? (
        <ThankYouPage />
    ) : (
        <div className="form-container">
            <div className="form-body">
                {inputs.map(({ placeholder, label, name, required }) => {
                    const handleInputChange = value => setInputsValues({ ...inputsValues, [name]: value });
                    return (
                        <FormControlInput
                            type={name}
                            value={inputsValues[name]}
                            onChange={handleInputChange}
                            label={label}
                            placeholder={placeholder}
                            key={name}
                            required={required}
                            className="form-item-half"
                        />
                    );
                })}
                <FormControlsTextArea
                    value={message}
                    onChange={handleTextareaChange}
                    label={cta_field_message_title}
                    placeholder={cta_field_message_placeholder}
                />
                <InputFile setFile={setFile} label={cta_field_file_title}>
                    {cta_field_file_placeholder}
                </InputFile>
            </div>
            <div className="form-footer">
                <Checkbox onChange={handleCheckboxChange} checked={agreed}>
                    <span dangerouslySetInnerHTML={{ __html: cta_policy_careers_text }} />
                </Checkbox>
                <ButtonSubmit onClick={handleSubmit} disabled={disabled} />
            </div>
        </div>
    );
};
