import { useSelector } from 'react-redux';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';

export const type_feedback = 'feedback';
export const type_anonymous = 'anonymous';

const FormTypeSwitchItem = ({ name, activeType, type, onClick }) => {
    const handleClick = () => onClick(type);

    return (
        <button className={`form-type-change__item ${activeType === type ? 'active' : ''}`} onClick={handleClick}>
            {name}
        </button>
    );
};

const FormTypeSwitch = ({ activeType, onClick }) => {
    const { cta_feedback_title, cta_anonymous_feedback_title } = useSelector(selectTranslations);

    return (
        <div className="form-type-change">
            <FormTypeSwitchItem
                onClick={onClick}
                name={cta_feedback_title}
                activeType={activeType}
                type={type_feedback}
            />
            <FormTypeSwitchItem
                onClick={onClick}
                name={cta_anonymous_feedback_title}
                activeType={activeType}
                type={type_anonymous}
            />
        </div>
    );
};

export default FormTypeSwitch;
