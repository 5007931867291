import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import { selectIsCookieAgree, setAgreeCookie } from 'store/slices/UI-Slice';

const CookieBanner = () => {
    const dispatch = useDispatch();
    const { cookie_icon, cookie_title, cookie_description, cookie_btn } = useSelector(selectTranslations);
    const isCookieAgree = useSelector(selectIsCookieAgree);
    const handleClick = () => dispatch(setAgreeCookie());

    return (
        !isCookieAgree && (
            <div className="cookie-popup">
                <div className="cookie-popup-content">
                    <div className="cookie-popup__title">
                        <img src={cookie_icon} alt="" />
                        {cookie_title}
                    </div>
                    <div className="cookie-popup__text" dangerouslySetInnerHTML={{ __html: cookie_description }} />
                </div>
                <button className="button-submit" onClick={handleClick}>
                    {cookie_btn}
                </button>
            </div>
        )
    );
};
export default CookieBanner;
