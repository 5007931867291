import axios from 'axios';
import config from 'api/urls-config.json';
import { API, product_items_per_request } from 'api/constants';

export const getCropNutrition = params => {
    return axios.get(`${API + config.pages.crop_nutrition_programs}`, { params });
};

export const getCropNutritionDetail = params => {
    const { slug, language, country, type, product } = params;

    return axios.get(`${API + config.crops + '/' + slug}`, {
        params: {
            language,
            country,
            type,
            slug,
            product,
        },
    });
};

export const getCropsForProductByTypeAndTrialsCountry = params => {
    const {
        slug,
        product,
        language,
        country,
        trials_country,
        type,
        page = 1,
        limit = product_items_per_request,
        all,
        exclusion_country,
    } = params;

    return axios.get(`${API + config.crops}`, {
        params: {
            language,
            country,
            product,
            trials_country,
            type,
            page,
            slug,
            limit,
            exclusion_country,
            all,
        },
    });
};
