import axios from 'axios';
import config from 'api/urls-config.json';
import { API, flyers_limit, type_trials } from 'api/constants';

export const getTrialResults = params => {
    return axios.get(`${API + config.pages.trail_results}`, { params });
};

export const getTrialResultsFlyers = params => {
    const { slug, language, country, page = 1, limit = flyers_limit, product, trials_country } = params;

    return axios.get(`${API + config.crops + '/' + slug + '/flyers'}`, {
        params: {
            language,
            country,
            page,
            limit,
            product,
            trials_country,
        },
    });
};

export const getDetailTrialResults = params => {
    const { slug, language, country, type = type_trials } = params;

    return axios.get(`${API + config.crops + '/' + slug}`, {
        params: {
            language,
            country,
            type,
        },
    });
};

export const getAllTrialResults = params => {
    const { language, country, type = type_trials, all = true } = params;

    return axios.get(`${API + config.crops}`, {
        params: {
            language,
            country,
            type,
            all,
        },
    });
};
