import axios from 'axios';
import config from 'api/urls-config.json';
import { API } from 'api/constants';

export const getLanguages = country => {
    return axios.get(`${API + config.languages_list}?country=${country}`);
};

export const getCountries = () => {
    return axios.get(`${API + config.countries_list}`);
};

export const getTranslations = params => {
    return axios.get(`${API + config.pages.translation}`, { params });
};

export const getPopupTranslations = params => {
    return axios.get(`${API + config.modal}`, { params });
};
