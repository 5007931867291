import { ReactComponent as CalendarIcon } from 'images/icons/calendar.svg';
import { ReactComponent as ClockIcon } from 'images/icons/clock.svg';

export const getIcon = iconName => {
    const icons = {
        calendar: <CalendarIcon />,
        clock: <ClockIcon />,
    };

    return icons[iconName];
};
