import { useSelector } from 'react-redux';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import './styles.scss';

const ButtonSubmit = ({ onClick, disabled = false }) => {
    const { cta_submit_button } = useSelector(selectTranslations);
    return (
        <button className="button-submit" onClick={onClick} disabled={disabled}>
            {cta_submit_button}
        </button>
    );
};

export default ButtonSubmit;
