import { createSelector, createSlice } from '@reduxjs/toolkit';
import { getCareer, getVacancies, getVacancy } from 'api/Career';
import { fetchWithLanguage } from 'store/helpers';

export const fetchCareer = fetchWithLanguage('fetchCareer', getCareer, (_, { getState }) => {
    const { data } = getState().careerState.career;
    return !data;
});
export const fetchVacancies = fetchWithLanguage('fetchVacancies', getVacancies);
export const fetchVacancy = fetchWithLanguage('fetchVacancy', getVacancy);

const careerSlice = createSlice({
    name: 'careerSlice',
    initialState: {
        career: {},
        vacanciesList: {
            data: [],
            next: false,
        },
        vacancyContent: {},
        vacanciesListIsLoading: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchVacancies.pending, (state, action) => {
            state.vacanciesListIsLoading = true;
        });
        builder
            .addCase(fetchVacancies.fulfilled, (state, action) => {
                const { payload, meta } = action;
                state.vacanciesList.next = payload.next;

                if (meta.arg.page === 1) {
                    state.vacanciesList.data = payload.data;
                } else {
                    state.vacanciesList.data.push(...payload.data);
                }
                state.vacanciesListIsLoading = false;
            })
            .addCase(fetchCareer.fulfilled, (state, action) => {
                state.career = action.payload;
            })
            .addCase(fetchVacancy.pending, (state, action) => {
                state.vacancyContent = {};
            })
            .addCase(fetchVacancy.fulfilled, (state, action) => {
                state.vacancyContent = action.payload;
            });
    },
});

export const selectCareerPage = createSelector([state => state.careerState.career], career => career.data || {});

export const selectCareerPageIsLoading = createSelector(
    [state => state.careerState.career, state => state.careerState.vacanciesListIsLoading],
    (career, vacanciesListIsLoading) => career?.status !== 'success' && vacanciesListIsLoading,
);

export const selectVacanciesList = createSelector(
    [state => state.careerState.vacanciesList],
    vacanciesList => vacanciesList || {},
);

export const selectVacancyContent = createSelector(
    [state => state.careerState.vacancyContent],
    vacancyContent => vacancyContent.data || {},
);
export const selectVacancyIsLoading = createSelector(
    [state => state.careerState.vacancyContent],
    vacancyContent => vacancyContent?.status !== 'success',
);
export default careerSlice.reducer;
