import { Link } from 'react-router-dom';
import './styles.scss';
import React from 'react';
import { getTextFromJSX } from 'utils';
import { useSelector } from 'react-redux';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';

const ProductCatalogBox = ({ title, image, description, file }) => {
    const { button_view } = useSelector(selectTranslations);

    return (
        <div className="box product-box">
            <h3>{title}</h3>
            <div className="product-catalog">
                <img src={image} alt={getTextFromJSX(title)} />
                <p className="body1 product-catalog__text">{description}</p>
                <div className="product-catalog__buttons">
                    <Link
                        to={file}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="button button-eye  has-children"
                    >
                        <div></div>
                        <div>{button_view}</div>
                    </Link>
                    {/* <Link
                        to={file}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="button download-button has-children"
                        download
                    >
                        <div></div>
                        <div>DOWNLOAD</div>
                    </Link> */}
                </div>
            </div>
        </div>
    );
};

export default ProductCatalogBox;