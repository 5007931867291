import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductCards from 'components/ProductCards/index';
import Banner from 'components/Banner';
import GradientText from 'components/GradientText';
import { LabelButton } from 'components/buttons/LabelButton/LabelButton';
import { selectMainProducts, selectProductsPage, selectProductsPageIsLoading } from 'store/slices/ProductsSlice';
import { fetchProducts } from 'store/slices/ProductsSlice';
import { scrollToSection } from 'helpers';
import { useAnimation } from 'hooks/useAnimation';
import Preloader from 'Layout/Preloader';

const Products = () => {
    const dispatch = useDispatch();

    const products = useSelector(selectMainProducts);
    const loading = useSelector(selectProductsPageIsLoading);

    const {
        fs_title,
        fs_title_background,
        fs_view_button,
        agricultural_tag,
        agricultural_title,
        agricultural_description,
    } = useSelector(selectProductsPage);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchProducts());
    }, [dispatch]);

    const handleClick = () => scrollToSection('product-card-container', 100);

    const container = useRef();

    useAnimation({
        items: ['.product-card '],
        container,
        dependencies: [loading],
    });

    if (loading) return <Preloader />;

    return (
        <div ref={container}>
            <Banner
                title={fs_title}
                image={fs_title_background}
                buttonText={fs_view_button}
                onClickButton={handleClick}
            />

            <section className="container">
                <div className="inner-container type-2">
                    <div className="main-text-container">
                        <LabelButton>{agricultural_tag}</LabelButton>
                        <GradientText className="has-animation">
                            <span dangerouslySetInnerHTML={{ __html: agricultural_title }} />
                        </GradientText>
                        <div className="offset-left-4 max-width-760">
                            <GradientText size="super-small" className="has-animation">
                                <span dangerouslySetInnerHTML={{ __html: agricultural_description }} />
                            </GradientText>
                        </div>
                    </div>
                </div>
            </section>

            <ProductCards data={products} />
        </div>
    );
};

export default Products;
