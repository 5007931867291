import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';
import 'swiper/css/effect-fade';

export const Slider = ({ children, settings = {}, className }) => {
    const sliderOptions = {
        slidesPerView: 1,
        navigation: false,
        loop: true,
        speed: 1000,
        autoplay: {
            delay: 5000,
        },
        effect: 'fade',
        ...settings,
    };

    return (
        <Swiper
            {...sliderOptions}
            modules={[Autoplay, EffectFade, Pagination]}
            pagination={{ clickable: true }}
            className={className}
        >
            {children}
        </Swiper>
    );
};

export { SwiperSlide as Slide };
