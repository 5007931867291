import { parseToJSX } from "./parseToJSX"



export const getDataWithReplacedFields = (data, dataType, info) => {
  const fieldsForReplace = [
    {key: 'title', subKeys: null},
    {key: 'preview_title', subKeys: null},
    {key: 'title_button_link', subKeys: null},
    {key: 'general_information_title', subKeys: null},
    {key: 'description', subKeys: null},
    {key: 'preview_description', subKeys: null},
    {key: 'benefits', subKeys: ['title', 'description']},
    {key: 'benefits_videos', subKeys: ['benefits_preview_text']},
    {key: 'catalogs', subKeys: ['title']},
    {key: 'details', subKeys: ['product']},
  ];

  if (!data.data) return dataType;

  const result = getMutationData(data, fieldsForReplace, dataType, replaceManyFields);

  // console.log('start ------------------');
  // console.log('info', info);
  // console.log('data', data);
  // console.log('data.data', data.data);
  // console.log('dataType', dataType);
  // console.log('res', result);
  // console.log('end --------------------');

  return result;
}

function getMutationData(data, fields, dataType, callback) {
  const isObject = typeof dataType === 'object' && dataType !== null && !Array.isArray(dataType);
  const isArray = Array.isArray(dataType);
  const isPagination = typeof dataType === 'string' && dataType === 'pagination';

  if (isPagination) {
    return {
      data: data.data.map(item => callback(item, fields)),
      next: data.next,
    };
  }

  if (isObject) {
    return callback(data.data, fields);
  }

  if (isArray) {
    return data.data.map(item => callback(item, fields));
  }
}

function replaceManyFields(srcObj, fieldsArr) {
  const mutationObj = JSON.parse(JSON.stringify(srcObj));

  fieldsArr.forEach((item) => {
    const {key, subKeys} = item;

    if (!subKeys) {
      if (mutationObj[key]) {
        mutationObj[key] = parseToJSX(mutationObj[key])
      }
    } else {
      const subData = mutationObj[key];
      let replacedSubData = subData;

      if (Array.isArray(subData)) {
        replacedSubData = subData.map((subItem) => {
          subKeys.forEach((subItemKey) => {
            if (subItem[subItemKey]) {
              subItem[subItemKey] = parseToJSX(subItem[subItemKey]);
            }
          });

          return subItem;
        });

        mutationObj[key] = replacedSubData;
      }
    }
  });

  return mutationObj;
}