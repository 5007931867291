import PDFCard from './PDFCard';
import './styles.scss';

const PDFBox = ({ title = '', data, children = undefined }) => {
    return (
        <div className="box pdf-box">
            {title && <h3>{title}</h3>}
            <div className="pdf-items">
                {data?.map((el, index) => (
                    <PDFCard key={index} data={el} />
                ))}
            </div>
            {children}
        </div>
    );
};

export default PDFBox;
