import { Link } from 'react-router-dom';
import { getTextFromJSX } from 'utils';

const ProductItem = ({ icon, slug, title }) => {
    return (
        <Link to={slug} className="product-item">
            <div className="product-item__image">{icon && <img src={icon} alt={getTextFromJSX(title)} />}</div>
            <div className="product-item__name">
                <p className="body2">{title}</p>
            </div>
        </Link>
    );
};

export default ProductItem;
