import axios from 'axios';
import config from 'api/urls-config.json';
import { API } from 'api/constants';

export const employees_limit = 10;

export const getAboutUsPage = params => {
    return axios.get(`${API + config.pages.about_us}`, { params });
};

export const getEmployees = params => {
    const { page, limit = employees_limit, language, country } = params;

    return axios.get(`${API + config.employees_list}`, {
        params: {
            page,
            limit,
            language,
            country,
        },
    });
};
