import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchSearch, resetSearch } from 'store/slices/SearchSlice';
import SearchInput from './SeachInput';
import SearchResults from './SearchResults';
import useDebounce from 'hooks/useDebounce';

const SearchComponent = () => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search);
    const inputRef = useRef(null);
    const handleChange = e => setSearch(e.target.value);

    const handleClear = () => {
        setSearch('');
        dispatch(resetSearch());
    };

    useEffect(() => {
        if (inputRef) {
            inputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (debouncedSearch) {
            // @ts-ignore
            dispatch(fetchSearch({ search: debouncedSearch }));
        }
    }, [dispatch, debouncedSearch]);

    return (
        <div className="search-component">
            <SearchInput inputRef={inputRef} value={search} onClear={handleClear} onChange={handleChange} />
            {!!search && <SearchResults />}
        </div>
    );
};

export default SearchComponent;
