import ProductItems from 'components/ProductItems';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { selectCropNutritionForProduct, fetchCropNutritionForProduct } from 'store/slices/CropNutritionSlice';
import { type_nutrition } from 'api/constants';
import ButtonShowMore from 'components/Button/ButtonShowMore';

const CropNutritionBox = ({ title }) => {
    const dispatch = useDispatch();
    const { slug } = useParams();
    const [page, setPage] = useState(1);
    const { data, next } = useSelector(selectCropNutritionForProduct);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchCropNutritionForProduct({ product: slug, type: type_nutrition, page }));
    }, [dispatch, slug, page]);

    const handleShowMore = () => setPage(page + 1);

    return (
        <div className="box product-box">
            {title && <h3>{title}</h3>}
            {data?.length && <ProductItems data={data} baseRoute={ROUTES.cropNutritionPrograms} />}
            <ButtonShowMore next={next} onClick={handleShowMore} />
        </div>
    );
};
export default CropNutritionBox;
