import { useEffect, useState } from 'react';
import JobCard from './JobCard';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVacancies, selectVacanciesList } from 'store/slices/CareerSlice';
import ButtonShowMore from 'components/Button/ButtonShowMore';

const JobCards = () => {
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const { data, next } = useSelector(selectVacanciesList);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchVacancies({ page }));
    }, [dispatch, page]);

    const handleShowMore = () => setPage(page + 1);

    return (
        !!data.length && (
            <>
                <div className="job-cards">
                    {data.map(item => (
                        <JobCard key={item.title} item={item} />
                    ))}
                </div>
                <ButtonShowMore next={next} onClick={handleShowMore} />
            </>
        )
    );
};

export default JobCards;
