import './styles.scss';
import ProductItem from './ProductItem';

const ProductItems = ({ data, baseRoute, withSixItems = false }) => {
    const dataWithChangedSlug = data?.map(item => ({
        ...item,
        __slug: baseRoute + '/' + item.slug,
        __icon: item.logo || item.icon,
        __title: item.preview_title || item.title,
    }));

    return (
        <div className={`product-items ${withSixItems ? 'six-items' : ''}`}>
            {dataWithChangedSlug?.map(({ __slug, __icon, __title }) => (
                <ProductItem key={__slug} icon={__icon} slug={__slug} title={__title} />
            ))}
        </div>
    );
};

export default ProductItems;
