import ProductCard from './ProductCard';
import './styles.scss';

const ProductCards = ({ data = [] }) => {
    return (
        <section className="container">
            <div className="inner-container type-2">
                <div className="product-card-container">
                    {data.map((item, index) => (
                        <ProductCard key={index} index={index} data={item} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ProductCards;
