import WithSidebar from 'Layout/WithSidebar';
import GeneralInfoBox from 'components/GeneralInfoBox';
import NavigationSideBar from 'components/NavigationSideBar';
import NavigationSectionWrapper from 'components/NavigationSideBar/NavigationSectionWrapper';
import { calculateActiveIndex } from 'components/NavigationSideBar/helpers';
import PDFBox from 'components/PDFBox';
import { createRef, useEffect, useState } from 'react';

const getContentByKey = (key, value) => {
    switch (key) {
        case 'block': {
            return (
                <GeneralInfoBox>
                    {value.title && <h3>{value.title}</h3>}
                    <div dangerouslySetInnerHTML={{ __html: value.text }}></div>
                </GeneralInfoBox>
            );
        }

        case 'pdf': {
            const { policy_title, flyers } = value;
            return <PDFBox title={policy_title} data={flyers} />;
        }

        default:
            return null;
    }
};

const AdditionalPageContent = ({ pageContentData }) => {
    const [sectionsData, setSectionsData] = useState({});
    const [activeIndex, setActiveIndex] = useState(0);

    const pageContent = [];
    const sidebarTitles = [];

    pageContentData.forEach(item => {
        Object.entries(item).forEach(([key, value]) => {
            const title = value.title || value.policy_title;
            if (title) {
                pageContent.push(getContentByKey(key, value));
                sidebarTitles.push(title);
            }
        });
    });

    const arrLength = pageContent.length;
    const [refs, setRefs] = useState([]);

    useEffect(() => {
        setRefs(refs =>
            Array(arrLength)
                .fill()
                .map((_, i) => refs[i] || createRef()),
        );
    }, [arrLength]);

    useEffect(() => {
        setActiveIndex(calculateActiveIndex(sectionsData));
    }, [sectionsData]);

    return (
        <section className="container">
            <WithSidebar
                containerType="type-1"
                Sidebar={<NavigationSideBar activeIndex={activeIndex} refs={refs} items={sidebarTitles} />}
            >
                {pageContent.map((item, index) => (
                    <div ref={refs[index]} key={index}>
                        <NavigationSectionWrapper index={index} setData={setSectionsData}>
                            {item}
                        </NavigationSectionWrapper>
                    </div>
                ))}
            </WithSidebar>
        </section>
    );
};
export default AdditionalPageContent;
