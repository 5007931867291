import { LabelButton } from 'components/buttons/LabelButton/LabelButton';
import FormSidebar from '../FormSidebar';
import GradientText from 'components/GradientText';
import { useSelector } from 'react-redux';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';

const DefaultFormSidebar = () => {
    const { cta_tag, cta_title, cta_image } = useSelector(selectTranslations);

    return (
        <FormSidebar>
            <LabelButton>{cta_tag}</LabelButton>
            <div className="pb-24">
                <GradientText size="middle" textColor="white">
                    <span dangerouslySetInnerHTML={{ __html: cta_title }} />
                </GradientText>
            </div>
            <img src={cta_image} alt="" />
        </FormSidebar>
    );
};

export default DefaultFormSidebar;
