import CountrySelectorMenu from './CountrySelectorMenu';
import './styles.scss';
import HeaderButton from '../HeaderButton';
import React, { useEffect, useRef } from 'react';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsCountryOpen, setCountryOpen } from 'store/slices/UI-Slice';

const CountrySelector = () => {
    const dispatch = useDispatch();
    const isCountryOpen = useSelector(selectIsCountryOpen);

    const { menu_change_country_text } = useSelector(selectTranslations);
    const ref = useRef(null);

    const onToggleMenu = () => {
        dispatch(setCountryOpen(!isCountryOpen));
    };

    useEffect(() => {
        const handleClickOutside = e => {
            const isClickDropdownItem = e.target.matches('li.body1');
            const autocomplete = e.target.closest('.MuiAutocomplete-root');
            const dropdown = e.target.closest('.MuiPopper-root');
            const determinate = e.target.closest('.determinate');

            if (
                ref.current &&
                !ref.current.contains(e.target) &&
                !determinate &&
                !isClickDropdownItem &&
                !autocomplete &&
                !dropdown
            ) {
                dispatch(setCountryOpen(false));
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div ref={ref} className="country-selector-container">
            <HeaderButton iconName="globe" text={menu_change_country_text} onClick={onToggleMenu} />
            {isCountryOpen && <CountrySelectorMenu onClose={onToggleMenu} />}
        </div>
    );
};

export default CountrySelector;
