import { ReactComponent as Icon1 } from 'assets/icons/MapPin.svg';
import { ReactComponent as Icon2 } from 'assets/icons/Buildings.svg';
import { ReactComponent as Icon3 } from 'assets/icons/SealCheck.svg';

export const getIcon = iconName => {
    const icons = {
        locations: <Icon1 />,
        work_type: <Icon2 />,
        experience: <Icon3 />,
    };
    return icons[iconName];
};
