import { ROUTES } from 'constants/routes';
import { Link } from 'react-router-dom';

const ProductItem = ({ data, index }) => {
    const { logo, preview_title, preview_image, slug } = data;

    return (
        <Link to={ROUTES.products + '/' + slug} className="product-link">
            <div className="product-link__name">
                {preview_title}
                <div className="gradient-number dark">
                    <span>{index + 1}</span>
                </div>
            </div>
            <div className="product-link__image" style={{ backgroundImage: `url("${preview_image}")` }}>
                <div className="product-link__image-logo">
                    <img src={logo} alt="product-logo" />
                </div>
            </div>
            <div className="button learn-more-arrow">
                <div></div>
            </div>
        </Link>
    );
};

export default ProductItem;
