import Footer from 'components/Footer';
import Header from 'components/HeaderNew';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { selectActiveLanguageAndContry } from 'store/slices/languageSlice';

const MainLayout = () => {
    const { language } = useSelector(selectActiveLanguageAndContry);

    return (
        <>
            <Header />
            <main className={'lang-' + language?.code}>
                <Outlet />
            </main>
            <Footer />
        </>
    );
};

export default MainLayout;
