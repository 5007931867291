import './styles.scss';

const Checkbox = ({ checked, onChange, children }) => {
    return (
        <div className="checkbox-container">
            <div className="checkbox">
                <input className={checked ? 'checked' : ''} type="checkbox" checked={checked} onChange={onChange} />
            </div>
            <div className="checkbox-label">{children}</div>
        </div>
    );
};

export default Checkbox;
