import pdf from 'images/icons/pdf.svg';
import './styles.scss';
import { Link } from 'react-router-dom';

const PDFCard = ({ data }) => {
    return (
        <div className="pdf-card">
            <div className="pdf-card__top">
                <img src={pdf} alt="pdf" />
                <div className="pdf-card__top__right-icons">
                    <Link to={data?.file} rel="noopener noreferrer" target="_blank">
                        <div className="button view" />
                    </Link>
                    <Link to={data?.file} target="_blank" rel="noopener noreferrer" download>
                        <div className="button download" />
                    </Link>
                </div>
            </div>
            <p className="body2">{data.title}</p>
        </div>
    );
};

export default PDFCard;
