import React from 'react';

const getFillList = (list, copyTimes = 1) => {
    let newlist = [];
    for (let i = 0; i < copyTimes; i++) {
        newlist.push(...list);
    }
    return newlist;
};

const MarqueeRow = ({ list, time, toRight = false }) => {
    const marqueeContainer = React.useRef(null);
    const marqueeArea = React.useRef(null);
    const [moveLeft, setMoveLeft] = React.useState(0);
    const [showList, setShowList] = React.useState(list);
    const [realTime, setRealTime] = React.useState(time);
    React.useEffect(() => {
        const containerWidth = Math.floor(marqueeContainer.current.offsetWidth);
        // const areaWidth = Math.floor(marqueeArea.current.scrollWidth);
        const areaWidth = Math.floor((448 + 16) * list.length);
        const copyTimes = Math.max(2, Math.ceil((containerWidth * 2) / areaWidth));
        const newMoveLeft = 1856 || areaWidth * Math.floor(copyTimes / 2);
        const newRealTime = time * parseFloat((newMoveLeft / containerWidth).toFixed(2));

        setShowList(getFillList(list, copyTimes));
        setMoveLeft(newMoveLeft);
        setRealTime(newRealTime);
    }, [list, time]);

    return (
        <div className="marquee-container" ref={marqueeContainer}>
            <div
                className="marquee-area"
                ref={marqueeArea}
                style={{
                    transform: `translateX(-${moveLeft}px)`,
                    animationDuration: `${realTime}s`,
                    animationDirection: `${toRight ? ' reverse' : ''}`,
                }}
            >
                {showList.map((image, index) => (
                    <div className="marquee-item" style={{ backgroundImage: `url("${image}")` }} key={index}></div>
                ))}
            </div>
        </div>
    );
};

export default MarqueeRow;
