import WithSidebar from 'Layout/WithSidebar';
import BenefitsBox from 'components/BenefitsBox';
import GeneralInfoBox from 'components/GeneralInfoBox';
import NavigationSideBar from 'components/NavigationSideBar';
import NavigationSectionWrapper from 'components/NavigationSideBar/NavigationSectionWrapper';
import { calculateActiveIndex } from 'components/NavigationSideBar/helpers';
import PDFBox from 'components/PDFBox';
import ProductCatalogBox from 'components/ProductCatalogBox';
import CropNutritionBox from 'components/ProductItemsBox/CropNutritionBox';
import RelatedProductsBox from 'components/ProductItemsBox/RelatedProductsBox';
import ProductItemsBoxWithTabs from 'components/ProductItemsBox/TrialResultsBoxWithTabs';
import { createRef, useEffect, useRef, useState } from 'react';
import { catalogKey } from '.';
import { useAnimation } from 'hooks/useAnimation';
import { selectProductContentIsLoading } from 'store/slices/ProductsSlice';
import { useSelector } from 'react-redux';
import useTimeout from 'hooks/useTimeout';
import TableBox from 'components/TableBox';
import TableBoxWithoutTable from 'components/TableBox/TableBoxWithoutTable';
import MulltipleVideos from 'components/MulltipleVideos';

const getContentByKey = (key, data) => {
    if (key === 'general_information') {
        const { main_title, general_information } = data;
        return (
            <GeneralInfoBox>
                <>
                    {main_title && <h3>{main_title}</h3>}
                    <div dangerouslySetInnerHTML={{ __html: general_information }}></div>
                </>
            </GeneralInfoBox>
        );
    } else if (key === 'table_data') {
        const { main_title, formula_table, formula_description } = data;
        return <TableBox title={main_title} table={formula_table} description={formula_description} />;
    } else if (key === 'text_block') {
        return <TableBoxWithoutTable title={data?.text} />;
    } else if (key === 'benefits_data') {
        return (
            <BenefitsBox title={data?.main_title} data={data?.benefits}>
                <MulltipleVideos data={data?.benefits_videos} />
            </BenefitsBox>
        );
    } else if (key === 'composition_compatibility') {
        const { main_title, composition_compatibility } = data;
        return <PDFBox title={main_title} data={composition_compatibility} />;
    } else if (key === 'related_products') {
        return <RelatedProductsBox title={data.main_title} />;
    } else if (key === 'nutrition_programs') {
        return <CropNutritionBox title={data.main_title} />;
    } else if (key === 'trial_results') {
        return <ProductItemsBoxWithTabs title={data.main_title} />;
    } else if (key.startsWith(catalogKey)) {
        return (
            <ProductCatalogBox
                file={data.data.file}
                title={data.main_title}
                image={data.data.image}
                description={data.data.description}
            />
        );
    } else {
        return null;
    }
};

const checkIsValidData = (key, value) => {
    if (key === 'benefits_data') {
        return value !== null;
    } else if (key === 'table_data') {
        return !!value?.formula_table;
    } else if (key === 'text_block') {
        return !!value?.text;
    } else if (key === 'composition_compatibility') {
        return value?.composition_compatibility?.length > 0;
    } else if (key === 'related_products' || key === 'trial_results' || key === 'nutrition_programs') {
        return value?.hasOwnProperty('main_title');
    } else if (key.startsWith('catalog_')) {
        return !!value?.data?.file;
    } else {
        return true;
    }
};
const AboutProductPageContent = ({ pageContentData }) => {
    const [sectionsData, setSectionsData] = useState({});
    const [activeIndex, setActiveIndex] = useState(0);
    const pageContent = [];
    const sidebarTitles = [];
    const loading = useSelector(selectProductContentIsLoading);
    const [ready, setReady] = useState(false);

    Object.entries(pageContentData).forEach(([key, value]) => {
        if (checkIsValidData(key, value)) {
            pageContent.push(getContentByKey(key, value));
            sidebarTitles.push(value.main_title);
        }
    });

    const arrLength = pageContent.length;
    const [refs, setRefs] = useState([]);

    useEffect(() => {
        setRefs(refs =>
            Array(arrLength)
                .fill()
                .map((_, i) => refs[i] || createRef()),
        );
    }, [arrLength]);

    useEffect(() => {
        setActiveIndex(calculateActiveIndex(sectionsData));
    }, [sectionsData]);

    useTimeout(() => setReady(true), 2000);

    const container = useRef();

    useAnimation({
        items: ['.box', '.picture-component'],
        container,
        dependencies: [loading, ready],
    });

    return (
        <div ref={container}>
            <WithSidebar
                Sidebar={
                    <NavigationSideBar
                        activeIndex={activeIndex}
                        refs={refs}
                        items={sidebarTitles}
                        includesTitle="formula_text"
                    />
                }
            >
                {pageContent.map((item, index) => (
                    <div ref={refs[index]} key={index}>
                        <NavigationSectionWrapper index={index} setData={setSectionsData}>
                            {item}
                        </NavigationSectionWrapper>
                    </div>
                ))}
            </WithSidebar>
        </div>
    );
};
export default AboutProductPageContent;
