import './styles.scss';

const FormControlsTextArea = ({ value, onChange, label, placeholder }) => {
    const handleChange = e => onChange(e.target.value);

    return (
        <div className="form-control-container">
            <label className="form-control">
                <span className="form-control__label">{label}</span>
                <textarea
                    className="form-control__textarea"
                    value={value}
                    onChange={handleChange}
                    placeholder={placeholder}
                />
            </label>
        </div>
    );
};

export default FormControlsTextArea;
