import GradientText from 'components/GradientText';
import './styles.scss';

const TableBoxWithoutTable = ({ title }) => (
    <div className="box table-box table-box-without-table">
        <GradientText size="middle" textColor="white">
            <span dangerouslySetInnerHTML={{ __html: title }} />
        </GradientText>
        <div className="table-box__piece" />
    </div>
);

export default TableBoxWithoutTable;
