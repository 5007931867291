export const calculateActiveIndex = data => {
    const percent = 50;
    let index = 0;

    for (const key in data) {
        if (data[key] > percent) {
            index = Number(key);
        }
    }

    return index;
};
