import { ReactComponent as Arrow } from 'assets/icons/chevron.svg';
import { Link } from 'react-router-dom';

const HeaderNavigationName = ({ name, link, hasList, onClickMobile = undefined, mobileClassName = '' }) => {
    const handleClick = () => onClickMobile(name);

    return hasList ? (
        <div className={`navigation-item__name ${mobileClassName}`} onClick={handleClick}>
            <span>{name}</span>
            {hasList && <Arrow className="navigation-item__name-arrow" />}
        </div>
    ) : (
        <Link to={link} className="navigation-item__name">
            <span>{name}</span>
        </Link>
    );
};

export default HeaderNavigationName;
