import { useState } from 'react';
import './styles.scss';
import { useSelector } from 'react-redux';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import FormControlInput from 'components/FormControls/Input';
import FormControlsTextArea from 'components/FormControls/TextArea';
import Dropdown from 'components/Dropdown';
import Checkbox from 'components/Checkbox';
import ButtonSubmit from 'components/Button/ButtonSubmit';
import { useLocation } from 'react-router-dom';
import { ThankYouPage } from './ThankYouPage';
import axios from 'axios';
import config from 'api/urls-config.json';
import FormTypeSwitch, { type_feedback } from './FormTypeSwitch';
import { API } from 'api/constants';
import CountrySelect from 'components/ContryAutocompleteDropdown';
import { selectCurrentCountry } from 'store/slices/languageSlice';

export const CTAForm = () => {
    const { pathname } = useLocation();
    const currentCountry = useSelector(selectCurrentCountry);

    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [agreed, setAgreed] = useState(false);
    const [category, setActiveOption] = useState(null);
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');
    const [activeType, setActiveType] = useState(type_feedback);
    const [country, setCountry] = useState(currentCountry);

    const [inputsValues, setInputsValues] = useState({
        name: '',
        email: '',
        phone: '',
        organization: '',
    });

    const isFeedback = activeType === type_feedback;

    const {
        cta_field_email_placeholder,
        cta_field_email_title,
        cta_field_message_placeholder,
        cta_field_message_title,
        cta_field_name_placeholder,
        cta_field_name_title,
        cta_field_organization_placeholder,
        cta_field_organization_title,
        cta_field_phone_placeholder,
        cta_field_phone_title,
        cta_field_sender_title,
        cta_policy_text,
        cta_field_sender_options,
        cta_field_subject_message_title,
        cta_field_subject_message_placeholder,
    } = useSelector(selectTranslations);

    const inputs = [
        {
            name: 'name',
            required: true,
            label: cta_field_name_title,
            placeholder: cta_field_name_placeholder,
        },
        {
            name: 'email',
            required: true,
            label: cta_field_email_title,
            placeholder: cta_field_email_placeholder,
        },
        {
            name: 'phone',
            label: cta_field_phone_title,
            placeholder: cta_field_phone_placeholder,
        },
        {
            name: 'organization',
            label: cta_field_organization_title,
            placeholder: cta_field_organization_placeholder,
        },
    ];

    const options = cta_field_sender_options?.map(item => ({ ...item, name: item.title }));

    const handleSelectChange = item => setActiveOption(item);
    const handleTextareaChange = message => setMessage(message);
    const handleCheckboxChange = () => setAgreed(!agreed);
    const handleSubmit = () => {
        if (!disabled) {
            setLoading(true);
            const feedbackData = {
                ...inputsValues,
                message,
                category: category?.value,
                from_page: pathname,
                country: country?.name,
            };
            const anonimusData = {
                message,
                subject,
                category: category?.value,
                from_page: pathname,
                anonymous: true,
                country: country?.name,
            };
            axios
                .postForm(`${API + config.form}`, isFeedback ? feedbackData : anonimusData)
                .then(response => {
                    setStatus(response.data.status);
                    setLoading(false);
                })
                .catch(function (error) {
                    console.log(error);
                    setLoading(false);
                });
        }
    };

    const disabled = isFeedback
        ? !agreed || inputsValues.name === '' || inputsValues.email === '' || loading || !country?.name
        : !agreed || loading || message === '' || subject === '' || !country?.name;

    return status === 'success' ? (
        <ThankYouPage />
    ) : (
        <div className={`form-container form-type-${activeType}`}>
            <div className="form-body">
                <FormTypeSwitch activeType={activeType} onClick={setActiveType} />
                {isFeedback &&
                    inputs.map(({ placeholder, label, name, required }) => {
                        const handleInputChange = value => setInputsValues({ ...inputsValues, [name]: value });
                        return (
                            <FormControlInput
                                type={name}
                                value={inputsValues[name]}
                                onChange={handleInputChange}
                                label={label}
                                placeholder={placeholder}
                                key={name}
                                required={required}
                                className="form-item-half"
                            />
                        );
                    })}
                <CountrySelect value={country} onChange={setCountry} withoutInternational />
                <Dropdown
                    label={cta_field_sender_title}
                    options={options}
                    selectedValue={category}
                    onChange={handleSelectChange}
                />
                {!isFeedback && (
                    <FormControlInput
                        value={subject}
                        onChange={setSubject}
                        label={cta_field_subject_message_title}
                        placeholder={cta_field_subject_message_placeholder}
                        type={undefined}
                    />
                )}
                <FormControlsTextArea
                    value={message}
                    onChange={handleTextareaChange}
                    label={cta_field_message_title}
                    placeholder={cta_field_message_placeholder}
                />
            </div>
            <div className="form-footer">
                <Checkbox onChange={handleCheckboxChange} checked={agreed}>
                    <span dangerouslySetInnerHTML={{ __html: cta_policy_text }} />
                </Checkbox>
                <ButtonSubmit onClick={handleSubmit} disabled={disabled} />
            </div>
        </div>
    );
};
