import './styles.scss';
import Banner from 'components/Banner';
import image from 'images/404-banner.jpg';
import { useSelector } from 'react-redux';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';

const Page404 = () => {
    const translations = useSelector(selectTranslations);

    return (
        <section className="container page-404">
            <Banner image={image} buttonText={translations['404_btn']} buttonType="banner" withCrumbs={false}>
                <h2>{translations['404_title']}</h2>
                <p className="body1 opacity-80">{translations['404_description']}</p>
            </Banner>
        </section>
    );
};
export default Page404;
