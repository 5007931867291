import './footer.scss';
import RouterLink from 'components/RouterLink';
import Logo from 'components/Logo';
import { useSelector } from 'react-redux';
import { selectMainProducts } from 'store/slices/ProductsSlice';
import { Link } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import { ReactComponent as Icon1 } from 'assets/icons/Mail Send Envelope.svg';
import { ReactComponent as Icon2 } from 'assets/icons/Phone Mobile Phone.svg';
import { ReactComponent as Icon3 } from 'assets/icons/MapPin (1).svg';
import { selectLanguages } from 'store/slices/languageSlice';
import { parseToJSX } from 'utils';

const Footer = () => {
    const products = useSelector(selectMainProducts);
    const languages = useSelector(selectLanguages);
    const {
        footer_description_text,
        menu_company_text,
        menu_about_text,
        menu_news_text,
        menu_career_text,
        menu_contacts_text,
        menu_products_text,
        menu_resources_text,
        menu_crop_nutrition_text,
        menu_trial_results_text,
        menu_information_materials_text,
        footer_social_media_text,
        footer_contacts_email_text,
        footer_contacts_phone_text,
        footer_contacts_address_text,
        footer_copyright_text,
        footer_terms_conditions_text,
        footer_legal_info_text,
        footer_instagram_link,
        footer_facebook_link,
        footer_youtube_link,
    } = useSelector(selectTranslations);

    const Start = () => (
        <div className="new-footer-start">
            <Logo />
            <p className="description-medium">{footer_description_text}</p>
        </div>
    );
    const Menu = () => (
        <div className="footer-links-block">
            <div className="footer-title">{menu_company_text}</div>
            <RouterLink pathName="aboutUs">{menu_about_text}</RouterLink>
            <RouterLink pathName="news">{menu_news_text}</RouterLink>
            <RouterLink pathName="career">{menu_career_text}</RouterLink>
            <RouterLink pathName="contacts">{menu_contacts_text}</RouterLink>
        </div>
    );
    const Products = () => (
        <div className="footer-links-block">
            <div className="footer-title">{menu_products_text}</div>
            {products.map(product => (
                <Link to={ROUTES.products + '/' + product.slug} className="button-text" key={product.slug}>
                    {product.preview_title}
                </Link>
            ))}
        </div>
    );

    const Resources = () => (
        <div className="footer-links-block">
            <div className="footer-title">{menu_resources_text}</div>
            <RouterLink pathName="cropNutritionPrograms">{menu_crop_nutrition_text}</RouterLink>
            <RouterLink pathName="trialResults">{menu_trial_results_text}</RouterLink>
            <RouterLink pathName="informationMaterials">{menu_information_materials_text}</RouterLink>
        </div>
    );

    const Social = () =>
        !footer_instagram_link && !footer_facebook_link && !footer_youtube_link ? (
            <></>
        ) : (
            <div>
                <div className="footer-title">{footer_social_media_text}</div>
                <div className="footer-social">
                    {footer_instagram_link && (
                        <Link target="_blank" to={footer_instagram_link} className="button instagram" />
                    )}
                    {footer_facebook_link && (
                        <Link target="_blank" to={footer_facebook_link} className="button facebook" />
                    )}
                    {footer_youtube_link && (
                        <Link target="_blank" to={footer_youtube_link} className="button youtube" />
                    )}
                </div>
            </div>
        );

    const Contacts = () => (
        <div className="footer-links-block">
            <div className="footer-title">{menu_contacts_text}</div>
            <a href={`mailto:${footer_contacts_email_text}`} className="footer-contact-link">
                <Icon1 />
                <span>{footer_contacts_email_text}</span>
            </a>
            <a href={`tel:${footer_contacts_phone_text}`} className="footer-contact-link">
                <Icon2 />
                <span>{footer_contacts_phone_text}</span>
            </a>
            <a href="/#" className="footer-contact-link">
                <Icon3 />
                <span>{footer_contacts_address_text}</span>
            </a>
        </div>
    );

    const bad_host_1 = window.location.host.includes('.ru');
    const bad_host_2 = window.location.host.includes('.ру');
    const hasBadLang = !!languages?.find(language => {
        const code = String(language.code).toLowerCase();
        return code === 'ru' || code === 'rus';
    });
    const hide = bad_host_1 || bad_host_2 || hasBadLang;

    const Copy = () => (
        <div className="footer-copy">
            <p className="copyright">{footer_copyright_text}</p>
            <div className="policies">
                <RouterLink pathName="termsAndConditions">{footer_terms_conditions_text}</RouterLink>
                <RouterLink pathName="legalInfo">{footer_legal_info_text}</RouterLink>
            </div>
            {hide ? (
                <div />
            ) : (
                <div className="goodface-bar">
                    <span className="body1">Created by</span>
                    <a href="https://goodface.agency" target="_blank" rel="noreferrer" className="goodface-link">
                        <span>Goodface</span>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18 9C18 13.9706 13.9706 18 9 18C4.02943 18 0 13.9706 0 9C0 4.02943 4.02943 0 9 0C13.9706 0 18 4.02943 18 9Z"
                                fill="#D4EE36"
                            />
                            <path
                                d="M14.3794 8.73535C14.3794 9.482 14.2382 10.2205 13.9647 10.9083C13.6912 11.596 13.2913 12.2184 12.7898 12.7409C12.2883 13.2633 11.6951 13.6755 11.0451 13.9559C10.3953 14.2363 9.70028 14.38 8.99943 14.38C8.29856 14.38 7.60357 14.2363 6.95373 13.9559C6.30377 13.6755 5.71061 13.2633 5.20912 12.7409C4.70753 12.2184 4.30767 11.596 4.0342 10.9083C3.76069 10.2205 3.61946 9.482 3.61946 8.73535H2.64648C2.64648 9.6044 2.81081 10.4649 3.13007 11.2678C3.44933 12.0707 3.91729 12.8002 4.50721 13.4147C5.09713 14.0292 5.79749 14.5167 6.56828 14.8492C7.33903 15.1818 8.16513 15.353 8.99943 15.353C9.8337 15.353 10.6598 15.1818 11.4306 14.8492C12.2014 14.5167 12.9017 14.0292 13.4916 13.4147C14.0815 12.8002 14.5495 12.0707 14.8688 11.2678C15.188 10.4649 15.3524 9.60437 15.3524 8.73535H14.3794Z"
                                fill="black"
                            />
                            <path
                                d="M7.94083 5.55843C7.94083 6.4356 7.52603 7.14667 7.01438 7.14667C6.50269 7.14667 6.08789 6.4356 6.08789 5.55843C6.08789 4.68128 6.50269 3.97021 7.01438 3.97021C7.52603 3.97021 7.94083 4.68128 7.94083 5.55843Z"
                                fill="black"
                            />
                            <path
                                d="M11.9115 5.5587C11.9115 6.43585 11.4968 7.14694 10.9851 7.14694C10.4734 7.14694 10.0586 6.43585 10.0586 5.5587C10.0586 4.68156 10.4734 3.97046 10.9851 3.97046C11.4968 3.97046 11.9115 4.68156 11.9115 5.5587Z"
                                fill="black"
                            />
                        </svg>
                    </a>
                </div>
            )}
        </div>
    );

    return (
        <footer className="container">
            <div className="new-footer">
                <div className="new-footer-row">
                    <Start />
                    <Menu />
                    <Products />
                    <Resources />
                </div>
                <div className="new-footer-row">
                    <div></div>
                    <Social />
                    <Contacts />
                </div>
                <Copy />
            </div>
        </footer>
    );
};

export default Footer;
