import axios from 'axios';
import config from 'api/urls-config.json';
import { API } from 'api/constants';

export const getLegalInfoPage = params => {
    return axios.get(`${API + config.pages.legal_info}`, { params });
};

export const getTermsConditions = params => {
    return axios.get(`${API + config.pages.terms_conditions}`, { params });
};
