import { createSlice, createSelector } from '@reduxjs/toolkit';
import { getContactsPage } from 'api/Contacts';
import { fetchWithLanguage } from 'store/helpers';

export const fetchContacts = fetchWithLanguage('fetchContacts', getContactsPage, (_, { getState }) => {
    const { data } = getState().contactsPagesState.contacts;
    return !data;
});

const contactsSlice = createSlice({
    name: 'contactsSlice',
    initialState: {
        contacts: {},
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchContacts.fulfilled, (state, action) => {
            state.contacts = action.payload;
        });
    },
});

export const selectContactsPage = createSelector(
    [state => state.contactsPagesState.contacts],
    contacts => contacts.data || {},
);

export const selectContactsPageIsLoading = createSelector(
    [state => state.contactsPagesState.contacts],
    contacts => contacts.status !== 'success',
);

export default contactsSlice.reducer;
