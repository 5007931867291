const TagsFilter = ({ tag, onClick, selectedTag }) => {
    const handleClick = () => onClick(tag);

    const selected = selectedTag?.type === tag?.type;

    return (
        <button onClick={handleClick} className={`tag-item ${selected ? 'selected' : ''}`}>
            {tag.name}
        </button>
    );
};

export default TagsFilter;
