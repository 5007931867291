import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from 'assets/icons/arrow-right.svg';
import React from 'react';
import { parseToJSX } from 'utils';

const HeaderNavigationCard = ({ item }) => (
    <Link className="header-navigation-card" to={item.link}>
        <span className="header-navigation-card__icons">
            {item.icons.map((icon, index) => (
                <img src={icon} alt="icon" key={index} />
            ))}
        </span>

        <span className="header-navigation-card__title" >
            { item.name }
            <Arrow />
        </span>
    </Link>
);

export default HeaderNavigationCard;
