import { configureStore } from '@reduxjs/toolkit';
import resourcesReducer from './slices/ResourcesSlice';
import productsReducer from './slices/ProductsSlice';
import trialResultsReducer from './slices/TrialResults';
import careerReducer from './slices/CareerSlice';
import homepageReducer from './slices/HomepageSlice';
import languageReducer from './slices/languageSlice';
import blogReducer from './slices/BlogSlice';
import cropNutritionReducer from './slices/CropNutritionSlice';
import informationMaterialsReducer from './slices/InformationMaterialsSlice';
import additionalPagesReducer from './slices/AdditionalPagesSlice';
import contactsReducer from './slices/ContactsSlice';
import aboutUsReducer from './slices/AboutUsSlice';
import transltionsReducer from './slices/GeneralTranslationsSlice';
import searchReducer from './slices/SearchSlice';
import uiReducer from './slices/UI-Slice';

export const store = configureStore({
    reducer: {
        blogState: blogReducer,
        careerState: careerReducer,
        homepageState: homepageReducer,
        languageState: languageReducer,
        productsState: productsReducer,
        resourcesState: resourcesReducer,
        trialResultsState: trialResultsReducer,
        cropNutritionState: cropNutritionReducer,
        informationMaterialsState: informationMaterialsReducer,
        additionalPagesState: additionalPagesReducer,
        contactsPagesState: contactsReducer,
        aboutUsState: aboutUsReducer,
        translationsState: transltionsReducer,
        searchState: searchReducer,
        ui: uiReducer,
    },
});

export default store;
