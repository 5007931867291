import { Link } from 'react-router-dom';
import './styles.scss';

const ProductSidebar = ({ title, data, baseRoute }) => {
    return (
        <div className="product-sidebar">
            <div className="description-demibold product-sidebar__title">{title}</div>
            <div className="product-sidebar-cards">
                {data?.map(item => (
                    <Link
                        to={baseRoute + '/' + item.slug}
                        key={baseRoute + '/' + item.slug}
                        className="product-sidebar-card"
                        title={item.title}
                    >
                        {item.icon ? <img src={item.icon} alt={item.title} /> : <span>{item.title}</span>}
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default ProductSidebar;
