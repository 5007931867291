import axios from 'axios';
import config from 'api/urls-config.json';
import { API, posts_per_request } from 'api/constants';

export const getBlog = params => {
    return axios.get(`${API + config.pages.blog}`, { params });
};

export const getPosts = params => {
    const { language, country, page = 1, limit = posts_per_request, tag } = params;

    return axios.get(`${API + config.post_list}`, {
        params: {
            language,
            country,
            page,
            limit,
            tag,
        },
    });
};

export const getPost = params => {
    const { slug, language, country } = params;

    return axios.get(`${API + config.pages.post_slug + slug}`, {
        params: {
            language,
            country,
        },
    });
};
