import './styles.scss';

const WithSidebarLayout = ({ Sidebar, children, mobileReversed = false, containerType = '', breakpoint = 'xl' }) => (
    <div className={`inner-container ${containerType}`}>
        <div className={`with-sidebar-layout ${mobileReversed ? 'mobile-reverse' : ''} ${breakpoint}`}>
            <div className="with-sidebar-layout__left">{Sidebar}</div>
            <div className="with-sidebar-layout__right">{children}</div>
        </div>
    </div>
);

export default WithSidebarLayout;
