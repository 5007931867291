import "./instagramCard.css";
import heart from "../../images/icons/heart.svg";
import comment from "../../images/icons/comment.svg";
import share from "../../images/icons/share.svg";
import bookmark from "../../images/icons/bookmark.svg";

export const InstagramCard = ({image }) => {
    return (
      <div className="instagram-card">
        <img src={image} alt="instagram card" />
        <div className="icons-wrapper">
          <div className="left-icons-wrapper">
            <img src={heart} alt="heart icon" />
            <img src={comment} alt="comment icon" />
            <img src={share} alt="share icon" />
          </div>
          <img src={bookmark} alt="bookmark icon" />
        </div>
      </div>
    );
}