import { useSelector } from 'react-redux';
import FormSidebar from '../FormSidebar';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';

const CareerFormSidebar = () => {
    const { cta_description } = useSelector(selectTranslations);

    return (
        <FormSidebar>
            <div className="career-form">
                <p className="description-medium" dangerouslySetInnerHTML={{ __html: cta_description }} />
            </div>
        </FormSidebar>
    );
};
export default CareerFormSidebar;
