import React from 'react';
import Banner from 'components/Banner';
import Label from 'components/Label';
import NewsSlider from 'components/NewsSlider';
import TextWithIcon from 'components/TextWithIcon';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPost, selectPostContent, selectPostContentIsLoading } from 'store/slices/BlogSlice';
import { selectPostsTypeTranslations } from 'store/slices/GeneralTranslationsSlice';
import { scrollToSection, with404Page } from 'helpers';
import Preloader from 'Layout/Preloader';

const Article = () => {
    const { slug } = useParams();
    const dispatch = useDispatch();
    const postContent = useSelector(selectPostContent);
    const postContentIsLoading = useSelector(selectPostContentIsLoading);
    const postsTranslations = useSelector(selectPostsTypeTranslations);
    const navigate = useNavigate();

    const handleClick = scrollToSection('article-content');

    React.useEffect(() => {
        with404Page(
            dispatch(
                // @ts-ignore
                fetchPost({ slug }),
            ),
            navigate,
        );
    }, [dispatch, slug]);

    const {
        up_text = '',
        down_text = '',
        section_text = [],
        title,
        title_background,
        type,
        crated_at,
        time_to_read,
        fs_button,
    } = postContent;

    const sectionBoxes = section_text
        .map(item => `<div class='box'><img src='${item.image}' alt='article-image' /><div>${item.text}</div></div>`)
        .join('');

    const content = `${up_text || ''}${sectionBoxes}${down_text || ''}`;
    const translatedType = postsTranslations[type];

    if (postContentIsLoading) return <Preloader />;

    return (
        <>
            <Banner image={title_background} buttonText={fs_button} onClickButton={handleClick}>
                <Label>{translatedType}</Label>
                <h2>{title}</h2>
                <div>
                    <TextWithIcon iconName="calendar" text={crated_at} />
                    <TextWithIcon iconName="clock" text={time_to_read} />
                </div>
            </Banner>

            <article className="article-content" dangerouslySetInnerHTML={{ __html: content }}></article>
            <NewsSlider />
        </>
    );
};

export default Article;
