import React from 'react';
import TagItem from './TagItem';
import './styles.scss';

const TagsFilter = ({ tags = [], selectedTag, onClick }) => {
    return (
        <div className="tags-filter">
            {tags.map(tag => (
                <TagItem key={tag.type} tag={tag} selectedTag={selectedTag} onClick={onClick} />
            ))}
        </div>
    );
};

export default TagsFilter;
