import './styles.scss';
import BenefitCard from './BenefitCard';

const BenefitsCards = ({ data, withCustomContent = false }) => (
    <div className="benefits-container">
        {data?.map((item, index) => (
            <BenefitCard key={index} item={item} index={index} withCustomContent={withCustomContent} />
        ))}
    </div>
);

export default BenefitsCards;
