import ButtonShowMore from 'components/Button/ButtonShowMore';
import YoutubeLink from 'components/YoutubeLink';
import './styles.scss';
import { useState } from 'react';

const LIMIT = 3;

const MulltipleVideos = ({ data }) => {
    const [state, setState] = useState(data?.length > LIMIT ? data?.slice(0, LIMIT) : data);
    const showButton = data?.length > LIMIT && state?.length === LIMIT;

    const handleClick = () => setState(data);

    return (
        <div className="mulltiple-videos">
            {state?.map(({ benefits_preview_text, benefits_preview_video, benefits_video }, index) => (
                <YoutubeLink
                    key={index}
                    image={benefits_preview_video}
                    href={benefits_video}
                    title={benefits_preview_text}
                />
            ))}
            {showButton && <ButtonShowMore onClick={handleClick} next />}
        </div>
    );
};

export default MulltipleVideos;
