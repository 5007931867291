import HeaderNavigationDropdown from './HeaderNavigationDropdown';
import HeaderNavigationName from './HeaderNavigationItemName';
import { useDispatch } from 'react-redux';
import { setNavigationItemHover } from 'store/slices/UI-Slice';

const HeaderNavigationItem = ({ category, mobileState, onClick }) => {
    const dispatch = useDispatch();

    const handleMouseOver = () => dispatch(setNavigationItemHover(true));
    const handleMouseLeave = () => dispatch(setNavigationItemHover(false));

    const isOpen = mobileState.find(item => item.name === category.name)?.isOpen;

    return (
        <div className="navigation-item" onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
            <HeaderNavigationName
                mobileClassName={isOpen ? 'opened' : ''}
                onClickMobile={onClick}
                link={category.link}
                name={category.name}
                hasList={!!category.items}
            />
            {category.items && <HeaderNavigationDropdown button={category.button} items={category.items} />}
        </div>
    );
};

export default HeaderNavigationItem;
