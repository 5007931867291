import { ROUTES } from 'constants/routes';
import variant1 from 'images/icons/headerIcons/variant1.svg';
import variant2 from 'images/icons/headerIcons/variant2.svg';
import variant3 from 'images/icons/headerIcons/variant3.svg';
import variant4 from 'images/icons/headerIcons/variant4.svg';
import variant5 from 'images/icons/headerIcons/variant5.svg';
import variant6 from 'images/icons/headerIcons/variant6.svg';
import variant7 from 'images/icons/headerIcons/variant7.svg';
import variant8 from 'images/icons/headerIcons/variant8.svg';

export const generateNavigationData = data => {
    const {
        menu_company_text,
        menu_products_text,
        menu_resources_text,
        menu_contacts_text,
        menu_about_text,
        menu_news_text,
        menu_career_text,
        menu_all_products_btn,
        menu_crop_nutrition_text,
        menu_trial_results_text,
        menu_information_materials_text,
        menu_all_resources_btn,
        formated_products,
    } = data;

    return [
        {
            id: 1,
            name: menu_company_text,
            items: [
                { name: menu_about_text, link: ROUTES.aboutUs, icons: [variant1] },
                { name: menu_news_text, link: ROUTES.news, icons: [variant8, variant2, variant7] },
                { name: menu_career_text, link: ROUTES.career, icons: [variant3] },
            ],
        },
        {
            id: 2,
            name: menu_products_text,
            button: {
                name: menu_all_products_btn,
                link: ROUTES.products,
            },
            items: formated_products,
        },
        {
            id: 3,
            name: menu_resources_text,
            button: {
                name: menu_all_resources_btn,
                link: ROUTES.resources,
            },
            items: [
                {
                    icons: [variant4],
                    name: menu_crop_nutrition_text,
                    link: ROUTES.cropNutritionPrograms,
                },
                {
                    icons: [variant5],
                    name: menu_trial_results_text,
                    link: ROUTES.trialResults,
                },
                {
                    icons: [variant6],
                    name: menu_information_materials_text,
                    link: ROUTES.informationMaterials,
                },
            ],
        },
        { id: 4, name: menu_contacts_text, link: ROUTES.contacts },
    ];
};
