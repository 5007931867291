/* eslint-disable jsx-a11y/anchor-has-content */
import uniferIcon from 'images/icons/uniferIcon.svg';
import './styles.scss';

const YoutubeLink = ({ image, href, title, icon = uniferIcon }) => {
    return (
        <div className="youtube-link">
            <div className="youtube-link__title">
                <img src={icon} alt="uniferX icon" />
                <p className="description-demibold">{title}</p>
            </div>
            <a href={href} target="_blank" className="button youtube youtube-link_button" rel="noreferrer" />
            <img src={image} alt="Video Thumbnail" className="youtube-link__image" />
        </div>
    );
};

export default YoutubeLink;
