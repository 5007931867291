import React from 'react';
import FormSection from '..';
import DefaultFormSidebar from '../Sidebars/Default';
import { CTAForm } from 'components/Form/CTAForm';

const DefaultForm = () => (
    <FormSection sidebar={<DefaultFormSidebar />}>
        <CTAForm />
    </FormSection>
);

export default DefaultForm;
