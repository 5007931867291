import { createSelector, createSlice } from '@reduxjs/toolkit';
import { getCropsForProductByTypeAndTrialsCountry } from 'api/CropNutritionPrograms';
import { getDetailTrialResults, getTrialResults, getTrialResultsFlyers } from 'api/TrialResults';
import { fetchWithLanguage } from 'store/helpers';
import { getDataWithReplacedFields, parseToJSX } from 'utils';

export const fetchTrialResults = fetchWithLanguage('fetchTrialResults', getTrialResults, (_, { getState }) => {
    const { data } = getState().trialResultsState.trialResults;
    return !data;
});
export const fetchTrialResultsFlyers = fetchWithLanguage('fetchTrialResultsFlyers', getTrialResultsFlyers);
export const fetchTrialResultsForProductForCurrentCountry = fetchWithLanguage(
    'fetchTrialResultsForProductForCurrentCountry',
    getCropsForProductByTypeAndTrialsCountry,
);
export const fetchTrialResultsForProductForOthers = fetchWithLanguage(
    'fetchTrialResultsForProductForOthers',
    getCropsForProductByTypeAndTrialsCountry,
);
export const fetchTrialResultsPageList = fetchWithLanguage(
    'fetchTrialResultsPageList',
    getCropsForProductByTypeAndTrialsCountry,
);
export const fetchDetailTrialResults = fetchWithLanguage('fetchDetailTrialResults', getDetailTrialResults);

const trialResultsSlice = createSlice({
    name: 'trialResultsSlice',
    initialState: {
        trialResults: {},
        trialResultsDetails: {},
        trialResultsFlyers: {
            flyers: [],
            next: false,
        },
        trialResultsPageList: {},
        allCropTrialForFilter: {
            data: [],
        },
        trialResultsForProductForCurrentCountry: {
            data: [],
            next: false,
        },
        trialResultsForProductForOthers: {
            data: [],
            next: false,
        },
        currentProductFilter: null,
        currentCountryFilter: null,
    },
    reducers: {
        setCurrentProductFilter: (state, action) => {
            state.currentProductFilter = action.payload;
        },
        setCurrentCountryFilter: (state, action) => {
            state.currentCountryFilter = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchTrialResults.fulfilled, (state, action) => {
                state.trialResults = action.payload;
            })
            .addCase(fetchDetailTrialResults.pending, (state, action) => {
                state.trialResultsDetails = {};
            })
            .addCase(fetchDetailTrialResults.fulfilled, (state, action) => {
                state.trialResultsDetails = action.payload;
            })
            .addCase(fetchTrialResultsPageList.fulfilled, (state, action) => {
                state.trialResultsPageList = action.payload;
                if (!state.allCropTrialForFilter.data.length) {
                    state.allCropTrialForFilter = action.payload;
                }
            })
            .addCase(fetchTrialResultsFlyers.fulfilled, (state, action) => {
                const { payload, meta } = action;
                state.trialResultsFlyers.next = payload.next;

                if (meta.arg.page === 1) {
                    state.trialResultsFlyers.flyers = payload.data;
                } else {
                    state.trialResultsFlyers.flyers.push(...payload.data);
                }
            })
            .addCase(fetchTrialResultsForProductForCurrentCountry.fulfilled, (state, action) => {
                const { payload, meta } = action;
                state.trialResultsForProductForCurrentCountry.next = payload.next;

                if (meta.arg.page === 1) {
                    state.trialResultsForProductForCurrentCountry.data = payload.data;
                } else {
                    state.trialResultsForProductForCurrentCountry.data.push(...payload.data);
                }
            })
            .addCase(fetchTrialResultsForProductForOthers.fulfilled, (state, action) => {
                const { payload, meta } = action;
                state.trialResultsForProductForOthers.next = payload.next;

                if (meta.arg.page === 1) {
                    state.trialResultsForProductForOthers.data = payload.data;
                } else {
                    state.trialResultsForProductForOthers.data.push(...payload.data);
                }
            });
    },
});

export const selectTrialResultsPage = createSelector(
    [state => state.trialResultsState.trialResults],
    trialResults => trialResults.data || {},
);
export const selectTrialResultsIsLoading = createSelector(
    [state => state.trialResultsState.trialResults],
    trialResults => trialResults?.status !== 'success',
);
export const selectTrialResultsFlyers = createSelector(
    [state => state.trialResultsState.trialResultsFlyers],
    trialResultsFlyers => trialResultsFlyers || {},
);
export const selectTrialResultsForProductForCurrentCountry = createSelector(
    [state => state.trialResultsState.trialResultsForProductForCurrentCountry],
    trialResultsForProductForCurrentCountry => trialResultsForProductForCurrentCountry || {},
);
export const selectTrialResultsForProductForOthers = createSelector(
    [state => state.trialResultsState.trialResultsForProductForOthers],
    trialResultsForProductForOthers => trialResultsForProductForOthers || {},
);
export const selectTrialResultsPageList = createSelector(
    [state => state.trialResultsState.trialResultsPageList],
    trialResultsPageList => trialResultsPageList.data || [],
);
export const selectDetailTrialResults = createSelector(
    [state => state.trialResultsState.trialResultsDetails],
    trialResultsDetails => getDataWithReplacedFields(trialResultsDetails, {}, 'selectDetailTrialResults'),
);

export const selectDetailTrialIsLoading = createSelector(
    [state => state.trialResultsState.trialResultsDetails],
    trialResultsDetails => trialResultsDetails?.status !== 'success',
);
export const selectAllCropTrialForFilter = createSelector(
    [state => state.trialResultsState.allCropTrialForFilter],
    allCropTrialForFilter => allCropTrialForFilter.data || [],
);

export const selectAllCropTrialsOptions = createSelector([selectAllCropTrialForFilter], data =>
    data.map(crop => ({
        value: crop.slug,
        name: parseToJSX(crop.title),
    })),
);

export const selectCurrentProductFilterForTrialResults = createSelector(
    [state => state.trialResultsState.currentProductFilter],
    currentProductFilter => currentProductFilter || null,
);

export const selectCurrentCountryFilterForTrialResults = createSelector(
    [state => state.trialResultsState.currentCountryFilter],
    currentCountryFilter => currentCountryFilter || null,
);

export const { setCurrentProductFilter, setCurrentCountryFilter } = trialResultsSlice.actions;

export default trialResultsSlice.reducer;
