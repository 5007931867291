import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';

const RouterLink = ({ children, pathName }) => {
    const path = ROUTES[pathName];

    return (
        <Link to={path} className="button-text">
            {children}
        </Link>
    );
};

export default RouterLink;
