import GradientText from 'components/GradientText';
import './styles.scss';
import background from 'images/our-history.jpg';

const OurHistory = ({ title, cards = [] }) => {
    const filtered = cards.filter(item => !!item.title && !!item.text);

    return (
        <section className="container">
            <div className="our-history-section" style={{ backgroundImage: `url("${background}")` }}>
                <div className="our-history-section-gradient" />
                <div className="our-history">
                    <div className="our-history__title h1 has-animation">{title}</div>
                    <div className="our-history-cards">
                        {filtered.map(item => (
                            <div key={item.title} className="history-card-wrapper">
                                <div className="angle-decor" />
                                <div className="history-card">
                                    <GradientText textColor="white">
                                        <em>{item.title}</em>
                                    </GradientText>
                                    <div className="body1 opacity-80">{item.text}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};
export default OurHistory;
