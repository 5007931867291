import { Button } from 'components/buttons/Button/Button';
import Label from 'components/Label';
import TextWithIcon from 'components/TextWithIcon';
import { ROUTES } from 'constants/routes';
import { useSelector } from 'react-redux';
import { selectPostsTypeTranslations } from 'store/slices/GeneralTranslationsSlice';
import { selectHomepage } from 'store/slices/HomepageSlice';

const HeroNewsCard = ({ card }) => {
    const { slug, title, title_background, type, time_to_read, crated_at } = card;
    const { fs_learn_more } = useSelector(selectHomepage);
    const postsTranslations = useSelector(selectPostsTypeTranslations);
    const translatedType = postsTranslations[type];

    return (
        <>
            <img className="news-wrapper-image" src={title_background} alt="img" />
            <div className="news-box">
                <div>
                    <Label>{translatedType}</Label>
                    <p className="description-demibold">{title}</p>
                    <div className="news-time-data">
                        <TextWithIcon iconName="calendar" text={crated_at} />
                        <TextWithIcon iconName="clock" text={time_to_read} />
                    </div>
                </div>
                <div className="news-box-bottom">
                    <Button href={ROUTES.news + '/' + slug} type="learn-more-arrow white-text">
                        {fs_learn_more || 'LEARN MORE'}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default HeroNewsCard;
