import { useEffect, useRef, useState } from 'react';
import './styles.scss';
import { useSelector } from 'react-redux';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import { useLocation } from 'react-router-dom';

const LIMIT = 500;

const GeneralInfoBox = ({ children }) => {
    const location = useLocation();
    const isProductPage = location.pathname.startsWith('/products');
    const { other_show_more_button } = useSelector(selectTranslations);
    const [height, setHeight] = useState(0);
    const ref = useRef(null);

    useEffect(() => {
        if (ref && isProductPage) {
            setHeight(ref.current.clientHeight);
        }
    }, []);

    const handleClick = () => setHeight(0);

    return isProductPage ? (
        <div className="box general-info-box">
            <div
                className={`general-info-box__inner ${height >= LIMIT ? 'closed' : ''}`}
                ref={ref}
                style={{ maxHeight: height >= LIMIT ? `${LIMIT}px` : 'none' }}
            >
                {children}
            </div>
            {height >= LIMIT && (
                <button className="button has-children read-more" onClick={handleClick}>
                    <div></div>
                    <span>{other_show_more_button}</span>
                </button>
            )}
        </div>
    ) : (
        <div className="box general-info-box">{children}</div>
    );
};

export default GeneralInfoBox;
