import { useState } from 'react';
import './styles.scss';
import { isEmailValid } from './helpers';
import { useSelector } from 'react-redux';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';

const FormControlInput = ({ value, onChange, label, placeholder, required = false, type, className = '' }) => {
    const { cta_required_error, cta_invalid_error } = useSelector(selectTranslations);
    const [invalid, setInvalid] = useState('');
    const handleChange = e => onChange(e.target.value);
    const handleBlur = () => {
        if (required) {
            setInvalid(value === '' ? cta_required_error : '');
        }
        if (type === 'email') {
            setInvalid(isEmailValid(value) ? '' : cta_invalid_error);
        }
    };
    return (
        <div className={`form-control-container ${className}`}>
            <label className={`form-control ${invalid ? 'invalid' : ''}`}>
                {label && (
                    <span className="form-control__label">
                        {label}
                        {required ? '*' : ''}
                    </span>
                )}
                <input
                    onBlur={handleBlur}
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange}
                    className="form-control__input"
                />
                {invalid && <div className="invalid-text">{invalid}</div>}
            </label>
        </div>
    );
};

export default FormControlInput;
