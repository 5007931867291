import Banner from 'components/Banner';
import React from 'react';
import AdditionalPageContent from './PageContent';
import { scrollToSection } from 'helpers';

const AdditionalPage = ({ title, background, buttonText, pageContentData, pageRef }) => {
    const handleClick = () => scrollToSection('with-sidebar-layout', 100);

    return (
        <div ref={pageRef}>
            <Banner title={title} image={background} buttonText={buttonText} onClickButton={handleClick} />
            <AdditionalPageContent pageContentData={pageContentData} />
        </div>
    );
};

export default AdditionalPage;
