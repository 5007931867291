import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
    getCropNutrition,
    getCropNutritionDetail,
    getCropsForProductByTypeAndTrialsCountry,
} from 'api/CropNutritionPrograms';
import { fetchWithLanguage } from 'store/helpers';
import { getDataWithReplacedFields } from 'utils';

export const fetchCropNutritionPage = fetchWithLanguage('fetchCropNutrition', getCropNutrition, (_, { getState }) => {
    const { data } = getState().cropNutritionState.cropNutritionPage;
    return !data;
});
export const fetchCropNutritionDetail = fetchWithLanguage('fetchCropNutritionDetail', getCropNutritionDetail);
export const fetchCropNutritionForProduct = fetchWithLanguage(
    'fetchCropNutritionForProduct',
    getCropsForProductByTypeAndTrialsCountry,
);
export const fetchCropNutritionPageCrops = fetchWithLanguage(
    'fetchCropNutritionPageCrops',
    getCropsForProductByTypeAndTrialsCountry,
);

const cropNutritionSlice = createSlice({
    name: 'cropNutritionSlice',
    initialState: {
        cropNutritionPage: {},
        cropNutritionPageCrops: {},
        cropNutritionPageCropsIsLoading: false,
        cropNutritionDetail: {},
        cropNutritionForProduct: {},
        allCropNutritionForFilter: {
            data: [],
        },
        currentProductFilter: null,
    },
    reducers: {
        setCurrentProductFilter: (state, action) => {
            state.currentProductFilter = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchCropNutritionPage.fulfilled, (state, action) => {
                state.cropNutritionPage = action.payload;
            })
            .addCase(fetchCropNutritionPageCrops.pending, (state, action) => {
                state.cropNutritionPageCropsIsLoading = true;
            })
            .addCase(fetchCropNutritionPageCrops.fulfilled, (state, action) => {
                state.cropNutritionPageCrops = action.payload;
                state.cropNutritionPageCropsIsLoading = false;
                if (!state.allCropNutritionForFilter.data.length) {
                    state.allCropNutritionForFilter = action.payload;
                }
            })
            .addCase(fetchCropNutritionDetail.pending, (state, action) => {
                state.cropNutritionDetail = {};
            })
            .addCase(fetchCropNutritionDetail.fulfilled, (state, action) => {
                state.cropNutritionDetail = action.payload;
            })
            .addCase(fetchCropNutritionForProduct.fulfilled, (state, action) => {
                const { payload, meta } = action;
                state.cropNutritionForProduct.next = payload.next;

                if (meta.arg.page === 1) {
                    state.cropNutritionForProduct.data = payload.data;
                } else {
                    state.cropNutritionForProduct.data.push(...payload.data);
                }
            });
    },
});

export const selectCropNutritionPage = createSelector(
    [state => state.cropNutritionState.cropNutritionPage],
    cropNutritionPage => cropNutritionPage.data || {},
);

export const selectCropNutritionPageIsLoading = createSelector(
    [
        state => state.cropNutritionState.cropNutritionPage,
        state => state.cropNutritionState.cropNutritionPageCropsIsLoading,
    ],
    (cropNutritionPage, cropNutritionPageCropsIsLoading) =>
        cropNutritionPage.status !== 'success' && cropNutritionPageCropsIsLoading,
);

export const selectCropNutritionPageCrops = createSelector(
    [state => state.cropNutritionState.cropNutritionPageCrops],
    cropNutritionPageCrops => getDataWithReplacedFields(cropNutritionPageCrops, [], 'selectCropNutritionPageCrops'),
);

export const selectAllCropNutritionForFilter = createSelector(
    [state => state.cropNutritionState.allCropNutritionForFilter],
    allCropNutritionForFilter => getDataWithReplacedFields(allCropNutritionForFilter, [], 'selectAllCropNutritionForFilter'),
);

export const selectAllCropNutritionOptions = createSelector([selectAllCropNutritionForFilter], data =>
    data.map(crop => ({
        value: crop.slug,
        name: crop.title,
    })),
);

export const selectCropNutritionDetail = createSelector(
    [state => state.cropNutritionState.cropNutritionDetail],
    cropNutritionDetail => getDataWithReplacedFields(cropNutritionDetail, {}, 'selectCropNutritionDetail'),
);

export const selectCropNutritionDetailIsLoading = createSelector(
    [state => state.cropNutritionState.cropNutritionDetail],
    cropNutritionDetail => cropNutritionDetail?.status !== 'success',
);

export const selectCropNutritionForProduct = createSelector(
    [state => state.cropNutritionState.cropNutritionForProduct],
    cropNutritionForProduct => getDataWithReplacedFields(cropNutritionForProduct, 'pagination', 'cropNutritionForProduct'),
);

export const selectCurrentProductFilterForCropNutrition = createSelector(
    [state => state.cropNutritionState.currentProductFilter],
    currentProductFilter => currentProductFilter || null,
);

export const { setCurrentProductFilter } = cropNutritionSlice.actions;

export default cropNutritionSlice.reducer;
