import axios from 'axios';
import config from 'api/urls-config.json';
import { API, product_items_per_request } from 'api/constants';

export const getProducts = params => {
    return axios.get(`${API + config.pages.products}`, { params });
};

export const getMainProducts = params => {
    const { language, country, page = 1, limit = 10 } = params;

    return axios.get(`${API + config.products_list}`, {
        params: {
            language,
            country,
            page,
            limit,
        },
    });
};

export const getProduct = params => {
    const { slug, language, country } = params;

    return axios.get(`${API + config.pages.product_slug + slug}`, {
        params: {
            language,
            country,
        },
    });
};

export const getRelatedProducts = params => {
    const { slug, language, country, page = 1, limit = product_items_per_request } = params;

    return axios.get(`${API + config.pages.product_slug + slug + '/related'}`, {
        params: {
            language,
            country,
            page,
            limit,
        },
    });
};
