import React, { useState } from 'react';
import './styles.scss';
import TabButton from './TabButton';

const Tabs = ({ children, preSelectedTabIndex = 0 }) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(preSelectedTabIndex);

    return (
        <div className="tabs">
            <div className="tabs-nav">
                {children.map((item, index) => (
                    <TabButton
                        key={item.props.title}
                        title={item.props.title}
                        index={index}
                        isActive={index === selectedTabIndex}
                        setSelectedTab={setSelectedTabIndex}
                    />
                ))}
            </div>

            {children[selectedTabIndex]}
        </div>
    );
};

export default Tabs;
