import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getCountries, getLanguages } from 'api/Languages';

export const fetchLanguages = createAsyncThunk('fetchLanguages', async country => {
    const response = await getLanguages(country);
    return response.data;
});

export const fetchCountries = createAsyncThunk(
    'fetchCountries',
    async () => {
        const response = await getCountries();
        return response.data;
    },
    {
        condition: (_, { getState }) => {
            // @ts-ignore
            const { countries } = getState().languageState;
            if (countries.length) return false;
        },
    },
);

export const languageKey = 'language';
export const countryKey = 'country';

const languageSlice = createSlice({
    name: 'languageSlice',
    initialState: {
        language: {},
        country: {},
        languages: [],
        languagesIsLoading: true,
        countriesIsLoading: true,
        countries: [],
    },
    reducers: {
        changeLanguage: (state, action) => {
            state.language = action.payload;
            localStorage.setItem(languageKey, JSON.stringify(action.payload));
        },
        changeCountry: (state, action) => {
            state.country = action.payload;
            localStorage.setItem(countryKey, JSON.stringify(action.payload));
        },
        setLanguages: (state, action) => {
            state.languages = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchLanguages.fulfilled, (state, action) => {
            state.languages = action.payload.data;

            const isAvailableLanguage = Boolean(
                action.payload.data.find(
                    language => language.code === JSON.parse(localStorage.getItem(languageKey))?.code,
                ),
            );
            if (localStorage.getItem(languageKey) && isAvailableLanguage) {
                state.language = JSON.parse(localStorage.getItem(languageKey));
            } else {
                const value = action.payload.data[0] || { name: 'English', icon: null, code: 'en' };
                localStorage.setItem(languageKey, JSON.stringify(value));
                state.language = value;
            }
            state.languagesIsLoading = false;
        });
        builder.addCase(fetchCountries.fulfilled, (state, action) => {
            const translatedCountries = action.payload.data.map(item => {
                const name = JSON.parse(item.name).text[state.language?.code || 'en'];
                return { ...item, name };
            });
            state.countries = translatedCountries;

            if (localStorage.getItem(countryKey)) {
                state.country = JSON.parse(localStorage.getItem(countryKey));
            } else {
                localStorage.setItem(countryKey, JSON.stringify(translatedCountries[0]));
                state.country = translatedCountries[0];
            }
            state.countriesIsLoading = false;
        });
    },
});

export const selectLanguageState = createSelector([state => state.languageState], data => data || {});

export const selectLanguages = createSelector([selectLanguageState], data => data.languages);

export const selectLanguagesAndCountriesIsLoading = createSelector(
    [state => state.languageState],
    languageState => languageState.languagesIsLoading || languageState.countriesIsLoading,
);

export const selectCurrentCountry = createSelector(
    [state => state.languageState],
    languageState => languageState.country,
);
export const selectCurrentCountryCode = createSelector([selectCurrentCountry], data => data.code);
export const selectCurrentLanguage = createSelector(
    [state => state.languageState],
    languageState => languageState.language,
);

export const selectActiveLanguageAndContry = createSelector(
    [selectCurrentCountry, selectCurrentLanguage],
    (country, language) => ({
        country,
        language,
    }),
);

export const selectCoutriesOptionsForFilter = createSelector([state => state.languageState.countries], data =>
    data.map(country => ({
        name: country.name,
        value: country.code,
    })),
);

export const { changeLanguage, changeCountry, setLanguages } = languageSlice.actions;

export default languageSlice.reducer;
