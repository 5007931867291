import { Link } from 'react-router-dom';

const ResultItemCard = ({ title, slug, img, desc }) => (
    <Link to={slug} className="result-item-card">
        {img && <img src={img} alt={title} />}
        <div className="result-item-card__desc">
            <p>{title}</p>
            <p>{desc}</p>
        </div>
        <div className="learn-more-arrow">
            <div></div>
        </div>
    </Link>
);

export default ResultItemCard;
